import React from 'react'
import { HeadingH3Small } from '../Typography'
import loadable from '@loadable/component'

const Form = loadable(() => import('./Form'))

export default function FormContainer(props) {
  return (
    <div
      className={`container mx-auto px-4 ${
        props.paddingSmall ? 'lg:pt-10 lg:pb-8 ' : 'lg:py-24 '
      } py-10  `}
      id={props.anchor}
    >
      <div className={`flex flex-wrap ${props.addExtraMarginTop ? 'mt-10' : ''}`}>
        <div className={`w-full lg:w-1/2 pb-12 lg:pb-4 lg:pr-10`}>
          {props.title && <HeadingH3Small className="mb-4">{props.title}</HeadingH3Small>}

          <div
            className={`text-base list-tick p-margin link-blue`}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </div>
        <div
          className={`w-full lg:w-1/2  ${
            props.whiteBox ? 'bg-white shadow rounded p-8' : 'lg:pl-4'
          }`}
        >
          {props.form && (
            <Form
              {...props.form}
              secondaryCta={props.form.ctaStyle === 'lime-green'}
              onSubmitted={props.onSubmitted}
            />
          )}
        </div>
      </div>
    </div>
  )
}
