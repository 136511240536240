import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ArrowSimple } from './Icons'

export default function CountrySelector(props) {
  return (
    <div className="mt-10 container mx-auto px-4">
      <div className="w-full border-b border-gray-300 py-1 flex justify-end">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="px-3 py-2 flex items-center">
                {props.selectItems.find((option) => option.selected).label}{' '}
                <ArrowSimple className="ml-4 w-4" />
              </Menu.Button>
              {open && (
                <div className="absolute z-10">
                  {props.selectItems && (
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Menu.Items as="ul" className="py-2 shadow-md rounded-sm">
                        {props.selectItems.map((option, i) => {
                          return (
                            <Menu.Item
                              key={`menu-item-${i}`}
                              as="li"
                              className="bg-white py-2 px-4 hover:bg-gray-100"
                              value={option.value}
                            >
                              <a href={option.value} className="block">
                                {option.label}
                              </a>
                            </Menu.Item>
                          )
                        })}
                      </Menu.Items>
                    </Transition>
                  )}
                </div>
              )}
            </>
          )}
        </Menu>
      </div>
    </div>
  )
}
