import React from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'

function TextCardBlock(props) {
  return (
    <div className="container mx-auto py-10 lg:py-20">
      <div className="flex flex-col justify-between px-6 md:flex-row">
        <div className="p-margin md:w-1/2 pb-8 md:mr-8">
          <h3 className="h3-light mb-6">{props.title}</h3>
          <h4 className="h5-muted mb-6">{props.subtitle}</h4>
          <p className="text-xl font-medium mb-6">{props.content}</p>
        </div>
        <div className="md:w-1/2 max-w-[485px] md:ml-8">
          <div className="bg-white rounded-2xl shadow-coverage px-10 ">
            <h4 className="text-xl font-medium py-8">{props.cardTitle}</h4>
            <ul className="">
              {props.cardItems?.map((x, i) => (
                <li className="flex pb-10 text-gray-700 " key={i}>
                  <ImageWrap className="mr-4" {...x.icon} />
                  {x.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextCardBlock
