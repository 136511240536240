import React, { useState, useEffect } from 'react'
import '../style.css'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { supportsVideoType } from '@truphone/core-components'
import LazyLoad from 'react-lazyload'

export default function TabsImages(props) {
  const [selected, setSelected] = useState(0)
  const [webmSupported, setWebmSupported] = useState(false)
  useEffect(() => {
    if (props.onSelectedChanged) {
      props.onSelectedChanged(selected)
    }
  }, [selected])

  useEffect(() => {
    setWebmSupported(supportsVideoType('webm'))
  }, [])

  return (
    <div className="relative h-56 lg:h-72 xl:h-96  text-white text-center overflow-hidden bg-gradient-after">
      {props.tabs && props.tabs[selected] && (
        <div className="w-full h-full relative">
          {props.tabs[selected].video && webmSupported ? (
            <LazyLoad height={384}>
              <video
                className="h-80 lg:h-auto min-w-full max-w-none"
                src={props.tabs[selected].video}
                poster={props.tabs[selected].image.url}
                autoPlay
                muted
                loop
              />
            </LazyLoad>
          ) : props.tabs[selected].image ? (
            <ImageWrap
              className="h-80 lg:h-auto min-w-full max-w-none"
              {...props.tabs[selected].image}
            />
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-between z-10">
        {props.tabs[selected].title && (
          <div className="flex-grow flex justify-center items-center w-full py-10 px-6">
            <h3 className="text-4xl-clamp font-normal">{props.title}</h3>
          </div>
        )}

        <div className="w-full mx-auto overflow-x-auto pb-5">
          <div className="flex-nowrap inline-flex justify-start xl:justify-center border border-white rounded-xl mx-6">
            {props.tabs &&
              props.tabs.map((tab, i) => {
                return (
                  <button
                    key={`tabs-image${i}`}
                    onClick={() => setSelected(i)}
                    className={`tabs-border relative whitespace-nowrap px-4 md:px-6 xl:px-10 lg:px-5 py-2 md:py-3 xl:py-4 text-xs md:text-sm xl:text-base font-bold tracking-widest xl:tracking-xwidest text-center uppercase rounded-lg ${
                      selected === i ? 'is-active bg-white text-black' : ''
                    }`}
                  >
                    {tab.title}
                  </button>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
