import React from 'react'
import { Arrow } from '@truphone/icons'
import { uppercaseString } from '@truphone/utils'

export default function Links(props) {
  return (
    <div className="text-white relative mt-10 md:mt-20">
      <div className="h-24 place-content-evenly hidden flex-col md:items-center md:flex-row md:inline-flex mx-auto">
        {props.links &&
          props.links.map((link, i) => {
            return (
              <a
                key={`link-cta-${i}`}
                href={link.href}
                className={`flex flex-row md:flex-col no-underline text-white text-center px-5 py-5 text-sm md:text-base font-bold md:tracking-xwidest`}
              >
                {uppercaseString(link.text)}
                <Arrow className="show-on-hover md:mx-auto md:mt-2 transform w-5 h-5" />
              </a>
            )
          })}
      </div>
    </div>
  )
}
