import React from 'react'
import '../style.css'
import { ArrowButton } from '@truphone/buttons'

function ItemsGroup(props) {
  return (
    props.items &&
    props.items.map((item, i) => (
      <div
        key={`group-item-${i}`}
        className={`p-4 w-full flex flex-col ${
          props.items.length === 4 ? 'md:w-1/4' : props.items.length === 3 ? 'md:w-1/3' : 'md:w-1/2'
        }`}
      >
        <h5 className="leading-8 text-xl lg:text-2xl-resp font-light">{item.title}</h5>
        {item.subtitle && <p>{item.subtitle}</p>}
        <div className="mt-auto">
          <ArrowButton
            className="text-blue-100 font-semibold mt-2 lg:mt-5 text-base lg:text-lg"
            href={item.ctaLink}
            target={item.ctaOpenNewTab ? '_blank' : ''}
          >
            {item.ctaText}
          </ArrowButton>
        </div>
      </div>
    ))
  )
}

export default ItemsGroup
