import React, { useState } from 'react'

export default function SimpleModal(props) {
  const [open, setOpen] = useState(props.isOpen)

  const handleClose = () => {
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = '0'
      setOpen(false)
    }
    if (props.setClose) {
      props.setClose(null)
    }
  }
  return (
    <>
      {(open || props.isOpen) && (
        <div className="fixed z-50 top-0 left-0 w-full h-full">
          <div
            className="fixed w-full h-full -z-1 bg-black bg-opacity-50"
            onClick={handleClose}
          ></div>
          <div
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full ${
              props.small ? 'max-w-xl' : 'max-w-3xl'
            }`}
          >
            <svg
              fill="currentColor"
              className="text-black hover:opacity-70 cursor-pointer absolute top-2 right-2 z-1 close-icon w-7 h-7"
              onClick={handleClose}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
            </svg>
            <div className="bg-white p-8 rounded-lg">{props.children}</div>
          </div>
        </div>
      )}
    </>
  )
}
