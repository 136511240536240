import React, { useState, useEffect } from 'react'
import Form from './form/Form'
import { Close } from '@truphone/icons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

const getOffset = (el) => {
  var _x = 0
  var _y = 0
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }
  return { top: _y, left: _x }
}
export default function GatedContent(props) {
  const [isOpen, setOpen] = useState(false)
  const [isScrolled, setScrolled] = useState(false)
  const [prevScroll, setPrevScroll] = useState(0)

  useEffect(() => {
    const onScroll = (e) => {
      const mobileForm = document.querySelector('#mobile-form')
      let offset = getOffset(mobileForm)

      setPrevScroll(e.target.documentElement.scrollTop)
      if (prevScroll >= offset.top - window.innerHeight) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    window.addEventListener('load', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [prevScroll, isScrolled])

  const handleOpen = (e) => {
    e.preventDefault()
    const gated = document.querySelector('#gated')

    if (gated) {
      gated.style.zIndex = '22'
    }
    document.querySelector('body').style.overflow = 'hidden'
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = 2147483641
      setOpen(true)
    }
  }
  const handleClose = (e) => {
    e.stopPropagation()

    const gated = document.querySelector('#gated')
    if (gated) {
      gated.style.zIndex = '1'
    }
    document.querySelector('body').style.overflow = 'visible'
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = '0'
      setOpen(false)
    }
    if (props.handleOpen) {
      props.handleOpen(false)
    }
  }

  return (
    <>
      <div className={`${props.hero ? 'absolute' : 'relative'} w-full overflow-hidden`}>
        {props.hero && props.hero.backgroundImage && (
          <div className="hidden lg:block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
            <ImageWrap
              className="block h-full w-full object-cover"
              {...props.hero.backgroundImage}
              loading="eager"
            />
          </div>
        )}
        {props.hero && props.hero.backgroundImageMobile && (
          <div className="lg:hidden w-full h-full absolute left-0 top-0 bg-cover bg-center">
            <ImageWrap
              className="block h-full w-full object-cover"
              {...props.hero.backgroundImageMobile}
              loading="eager"
            />
          </div>
        )}

        {
          /* Fallback if no mobile background */
          props.hero && !props.hero.backgroundImageMobile && props.hero.backgroundImage && (
            <div className="lg:hidden w-full h-full absolute left-0 top-0 bg-cover bg-center">
              <ImageWrap
                className="block h-full w-full object-cover"
                {...props.hero.backgroundImage}
                loading="eager"
              />
            </div>
          )
        }

        {props.hero && (
          <div
            className={` mx-auto flex flex-wrap pt-20 ${
              props.hero.textColor === 'dark' ? 'text-black' : 'text-white'
            } container pb-20`}
          >
            <div
              className={`${
                props.hero ? 'w-full lg:w-1/2 p-6 pb-0 shrink-0' : 'text-center my-10'
              }`}
            >
              {props.hero.topTitle && (
                <p className="overline-text uppercase mb-4">{props.hero.topTitle}</p>
              )}
              {props.hero.title && (
                <h1 className="heron text-3xl lg:text-6xl uppercase">{props.hero.title}</h1>
              )}
              {props.hero.subtitle && (
                <p className="text-base lg:text-2xl font-light mt-4">{props.hero.subtitle}</p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={`relative z-20  ${props.hero ? 'pt-20' : 'pt-10'}`}>
        <div className="container mx-auto flex flex-wrap">
          <div className="w-full lg:w-1/2 p-6">
            {props.hero && (
              <div
                className={`${props.hero.textColor === 'dark' ? 'text-black' : 'text-white'} pb-20`}
              >
                {props.hero.topTitle && (
                  <p
                    className="overline-text uppercase mb-4"
                    style={{
                      color: props.hero.topTitleColor ? props.hero.topTitleColor.hex : null
                    }}
                  >
                    {props.hero.topTitle}
                  </p>
                )}
                {props.hero.title && (
                  <h1 className="heron text-3xl lg:text-6xl uppercase">{props.hero.title}</h1>
                )}
                {props.hero.subtitle && (
                  <p className="text-base lg:text-2xl font-light mt-4">{props.hero.subtitle}</p>
                )}
              </div>
            )}
            {props.content && (
              <div
                className={`text-base p-margin list-tickbox-green ${
                  props.hero ? 'pt-10 lg:pt-20' : ''
                } pb-10`}
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            )}

            {props.speakers && (
              <>
                {props.speakersTitle && (
                  <p className="overline-text uppercase mb-4">{props.speakersTitle}</p>
                )}
                {props.speakers.map((speaker, i) => {
                  return (
                    <div key={`speaker-${i}`} className="mb-8">
                      <div className="flex items-center">
                        {speaker.image && (
                          <div className="mr-3 rounded-full w-14 h-14 overflow-hidden">
                            <ImageWrap {...speaker.image} />
                          </div>
                        )}
                        <div>
                          {speaker.fullName && (
                            <h4 className="text-2xl font-light">{speaker.fullName}</h4>
                          )}
                          {speaker.jobTitle && (
                            <p className="text-sm font-light">{speaker.jobTitle}</p>
                          )}
                        </div>
                      </div>
                      {speaker.bio && (
                        <p className="mt-4 italic leading-loose whitespace-pre-wrap">
                          {speaker.bio}
                        </p>
                      )}
                    </div>
                  )
                })}
              </>
            )}

            {props.footer && (
              <div
                className="text-base p-margin list-tickbox-green pb-10"
                dangerouslySetInnerHTML={{ __html: props.footer }}
              />
            )}
          </div>

          <div className="w-full lg:w-1/2 p-6">
            <div id="mobile-form" />
            <div
              className={`shadow-lg rounded-lg ${
                isScrolled ? 'relative w-full' : 'fixed px-2 lg:px-0 bottom-0 left-0'
              }  z-10 transition-all lg:transition-none lg:w-full lg:sticky lg:bottom-auto lg:top-4 ${
                isScrolled || isOpen ? 'max-h-full' : 'max-h-16 lg:max-h-full'
              } ${isOpen ? 'overflow-y-auto' : ''}`}
            >
              {props.floatingButton && (
                <button
                  className={`${
                    isScrolled ? 'cursor-default' : ''
                  } w-full relative h-16 flex justify-center items-center lg:hidden text-lg p-4 bg-blue-100 text-white rounded-t-lg`}
                  onClick={(e) => !isScrolled && handleOpen(e)}
                >
                  {props.floatingButton}

                  <Close
                    className={`${
                      isOpen && !isScrolled ? 'block' : 'hidden'
                    } text-white absolute top-4 right-3`}
                    onClick={(e) => handleClose(e)}
                  />
                </button>
              )}
              {props.form && (
                <div className="bg-white text-black p-8 lg:rounded-lg shadow">
                  {props.form.title && (
                    <p className="overline-text uppercase mb-4">{props.form.title}</p>
                  )}
                  <Form
                    {...props.form}
                    onSubmitted={() => {
                      if (props.thankYouPage?.url) {
                        window.location = props.thankYouPage.url
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-20 px-6 flex justify-between"></div>
    </>
  )
}
