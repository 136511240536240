import React, { useState } from 'react'
import { uppercaseString } from '@truphone/utils'
import { SecondaryButton } from '@truphone/buttons'

export default function TabbedBlock(props) {
  const [selected, setSelected] = useState(1)

  return (
    <div
      className={`${props.backgroundGradient === 'business' ? 'business-gradient' : ''} ${
        props.includePaddingBottom ? 'py-20' : 'pt-20'
      } `}
      style={{ color: props.textColor?.hex }}
    >
      <div className="container mx-auto px-6 flex flex-wrap w-full">
        <div className="w-full flex flex-col lg:w-1/2">
          {props.tabs && (
            <>
              <div className="flex-nowrap w-full lg:max-w-md inline-flex justify-center mx-auto">
                {props.tabs.map((tab, i) => {
                  return (
                    <button
                      key={`tab-cta-${i}`}
                      onClick={() => setSelected(i)}
                      className={`border border-grey-700 relative w-1/2 leading-none px-2 py-4 text-base text-center ${
                        selected === i ? 'is-active bg-white text-black' : ''
                      } ${
                        i === 0
                          ? 'rounded-l-lg '
                          : i === props.tabs.length - 1
                          ? 'rounded-r-lg'
                          : ''
                      }`}
                      style={{ borderColor: props.textColor?.hex }}
                    >
                      {tab.tabText}
                    </button>
                  )
                })}
              </div>
              {props.tabs.map((tab, i) => {
                return (
                  tab.image &&
                  selected === i && (
                    <div
                      key={`tab-image-${i}`}
                      className="flex h-full w-full justify-center lg:items-end py-10 lg:px-6 lg:pb-0"
                    >
                      <img src={tab.image.url} alt={tab.image.alt} />
                    </div>
                  )
                )
              })}
            </>
          )}
        </div>
        <div className="w-full lg:w-1/2 lg:pl-10 pb-10">
          {props.tabs &&
            props.tabs.map((tab, i) => {
              return (
                <div
                  key={`tab-component${i}`}
                  className={` ${selected === i ? 'block' : 'hidden'}`}
                >
                  {tab.topTitle && (
                    <p className={`overline-text mb-4`}>{uppercaseString(tab.topTitle)}</p>
                  )}
                  <h2 className="h3-light mb-4 leading-tight ">{tab.title}</h2>

                  <div
                    className="text-base article-content"
                    dangerouslySetInnerHTML={{ __html: tab.content }}
                  />
                  {tab.cta && (
                    <SecondaryButton
                      targer={tab.cta.openTab ? '_blank' : ''}
                      className={`mt-8 lg:text-base`}
                      href={tab.cta.href}
                    >
                      {tab.cta.text}
                    </SecondaryButton>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
