import React from 'react'
import { Arrow } from './Icons'
import '../style.css'
import { PrimaryButton } from '@truphone/buttons'
import Title from './Title'

function BenefitContent(props) {
  const innerContent = (
    <>
      <h5 className="font-semibold mb-2">{props.title}</h5>
      <p>{props.content}</p>
      {props.href && (
        <div className="flex mt-2">
          <Arrow className="ml-auto w-6 h-6" />
        </div>
      )}
    </>
  )

  return props.href ? (
    <a
      className={`text-base h-full block px-5 py-6 ${
        props.boxColour === 'telecoms'
          ? 'bg-blue-900'
          : props.boxColour === 'iot'
          ? 'bg-green-300'
          : props.boxColour === 'finance'
          ? 'bg-blue-300'
          : 'bg-blue-100'
      } text-white rounded-sm hover:bg-gradient-benefits`}
    >
      {innerContent}
    </a>
  ) : (
    <div
      className={`text-base h-full block px-5 py-6 ${
        props.boxColour === 'navy'
          ? 'bg-blue-900'
          : props.boxColour === 'torquise'
          ? 'bg-green-300'
          : props.boxColour === 'deep-blue'
          ? 'bg-blue-300'
          : 'bg-blue-100'
      } text-white rounded-sm hover:bg-gradient-benefits`}
    >
      {innerContent}
    </div>
  )
}

export default function BlockBenefits(props) {
  return (
    <div
      id={props.id}
      className={`py-10 lg:py-24 ${props.befefitsOnLeft ? 'bg-white' : 'bg-grey-100'}`}
    >
      <Title className="max-w-4xl mb-8" title={props.title} subtitle={props.subtitle} />
      <div className="container mx-auto px-4">
        <div className={`-mx-5 flex flex-wrap ${props.befefitsOnLeft ? 'flex-row-reverse' : ''}`}>
          <div className="w-full lg:w-1/2 p-5">
            {props.content && (
              <div
                className="p-margin text-base lg:pr-10"
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            )}
            {props.cta && (
              <PrimaryButton
                className="mt-6 mb-10 w-full sm:w-auto py-3"
                href={props.cta.href}
                target={props.cta.openTab ? '_blank' : ''}
              >
                {props.cta.text}
              </PrimaryButton>
            )}
          </div>
          <div className="w-full lg:w-1/2 p-5">
            {props.benefits && (
              <div className="flex flex-nowrap lg:flex-wrap -m-3 overflow-x-auto">
                {props.benefits.map((item, i) => {
                  return (
                    <div key={`item-${i}`} className="min-w--250 w-full md:w-1/2 p-3">
                      <BenefitContent {...item} />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
