import React from 'react'
import { ArrowButton, OutlinedButtonBlack, PrimaryButton } from '@truphone/buttons'
import { uppercaseString } from '@truphone/utils'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function CenteredBlock(props) {
  return (
    <div
      className={`relative ${props.backgroundImage ? 'lg:py-20 px-5' : ''} ${
        props.large ? 'py-20' : 'py-10'
      } flex items-center`}
      style={{ color: props.textColor?.hex, minHeight: props.backgroundImage?.height }}
    >
      {props.backgroundImage && (
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={props.backgroundImage.url}
          alt={props.backgroundImage.alt || ''}
        />
      )}
      <div
        className={`${
          props.addGreyBackground
            ? 'bg-grey-100 rounded-2xl py-10  box-content text-center'
            : 'md:text-center'
        } ${props.backgroundImage ? 'z-1 relative' : ''} ${
          props.large ? 'max-w-6xl text-center' : 'max-w-790'
        } mx-auto`}
      >
        <div className={`${props.backgroundImage ? 'px-5 lg:px-10' : 'px-4 lg:px-20'}`}>
          {props.icon && (
            <ImageWrap className="w-40 mx-auto mb-8" src={props.icon.url} alt={props.icon.alt} />
          )}

          {props.topTitle && (
            <p className={`overline-muted mb-4`} style={{ color: props.textColor?.hex }}>
              {uppercaseString(props.topTitle)}
            </p>
          )}
          <h2
            className={`h4-medium whitespace-pre-wrap mb-6`}
            style={{ color: props.textColor?.hex }}
          >
            {props.title}
          </h2>

          {props.subtitle && (
            <h3 className={`text-xl font-medium mb-6`} style={{ color: props.textColor?.hex }}>
              {props.subtitle}
            </h3>
          )}

          {props.images && props.images.length > 0 && (
            <div className="flex flex-col md:flex-row items-center mb-6">
              {props.images.map((image, i) => {
                return (
                  <ImageWrap
                    key={`image-logo-${i}`}
                    className="my-8 md:mx-auto md:my-10"
                    src={image.url}
                    alt={image.alt}
                  />
                )
              })}
            </div>
          )}

          {props.content && (
            <div
              className={`body1-muted link-blue ${
                props.large ? 'max-w-5xl' : 'max-w-xl'
              } mx-auto  p-margin`}
              dangerouslySetInnerHTML={{ __html: props.content }}
              style={{ color: props.textColor?.hex }}
            />
          )}

          {props.ctas && props.ctas.length > 0 && (
            <div className="flex flex-wrap justify-center items-center mt-8 sm:-mx-2">
              {props.ctas.map((cta, i) => {
                return (
                  <div
                    key={`centered-cta-${i}`}
                    className={`${props.large ? '' : 'w-full sm:w-1/2 '} p-2`}
                  >
                    {cta.primary ? (
                      <PrimaryButton
                        className={`px-3 whitespace-nowrap`}
                        key={`cta-block-${i}`}
                        target={cta.openTab ? '_blank' : null}
                        href={cta.href}
                      >
                        {cta.text}
                      </PrimaryButton>
                    ) : cta.arrow ? (
                      <ArrowButton
                        className={`text-lg px-3 whitespace-nowrap`}
                        key={`cta-block-${i}`}
                        target={cta.openTab ? '_blank' : null}
                        href={cta.href}
                      >
                        {cta.text}
                      </ArrowButton>
                    ) : (
                      <OutlinedButtonBlack
                        className={`w-full px-3 whitespace-nowrap`}
                        key={`cta-block-${i}`}
                        target={cta.openTab ? '_blank' : null}
                        href={cta.href}
                      >
                        {cta.text}
                      </OutlinedButtonBlack>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
