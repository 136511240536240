import React from 'react'
import { LinkWrap } from '@truphone/gatsby-wrappers'
export default function Card(props) {
  return props.cta ? (
    <LinkWrap
      href={props.cta.href}
      className={`${
        props.gradient
          ? 'h-full bg-blue-300 text-white hover:finance-gradient'
          : 'bg-white text-black'
      } p-6 block rounded-lg shadow-sm`}
    >
      {props.title && <h3 className="text-xl mb-2">{props.title}</h3>}
      {props.content && (
        <div className="text-sm" dangerouslySetInnerHTML={{ __html: props.content }} />
      )}
    </LinkWrap>
  ) : (
    <div
      className={`${
        props.gradient ? 'bg-blue-300 text-white hover:finance-gradient' : 'bg-white text-black'
      } p-6 block rounded-lg shadow-sm`}
    >
      {props.title && <h3 className="text-xl mb-2">{props.title}</h3>}
      {props.content && (
        <div className="text-sm" dangerouslySetInnerHTML={{ __html: props.content }} />
      )}
    </div>
  )
}
