import React from 'react'
import { ArrowButton } from '@truphone/buttons'
import '../style.css'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function Related(props) {
  return (
    <div
      className={`${props.noMarginTop ? '' : 'pt-10 lg:pt-24'} pb-10 lg:pb-14 ${
        props.bgGrey ? 'bg-grey-100' : 'bg-white'
      }`}
      style={{ backgroundColor: props.backgroundColor?.hex }}
    >
      <div className="container mx-auto px-6">
        <h2 className="text-2xl lg:text-4xl font-normal mb-4 lg:mb-8 text-center">{props.title}</h2>
        {props.items && (
          <div
            className={`container mx-auto relative flex flex-wrap ${
              props.items.length === 2 ? 'justify-center' : ''
            }`}
          >
            {props.items.map((el, i) => {
              return (
                <div
                  key={`featured-${i}`}
                  className={`w-full ${
                    props.items.length === 2
                      ? 'w-[410px]'
                      : props.items.length % 3 === 0
                      ? 'sm:w-1/3'
                      : 'sm:w-1/2 md:w-1/4'
                  }`}
                >
                  <div className={`p-2 lg:p-4 h-full flex flex-col `}>
                    {el.image && <ImageWrap className="w-full" {...el.image} />}
                    <h3
                      className={`mt-5 ${
                        props.items.length % 3 === 0
                          ? 'text-base lg:font-normal lg:text-2xl'
                          : props.itemsTitleFontStyle === 'h4'
                          ? 'h4-medium'
                          : 'text-base lg:text-xl font-semibold'
                      } `}
                    >
                      {el.title}
                    </h3>
                    <div
                      className="mt-2 text-sm lg:text-base mb-2"
                      dangerouslySetInnerHTML={{ __html: el.content }}
                    />
                    {el.ctaText && (
                      <ArrowButton
                        className="text-sm lg:text-lg font-semibold text-blue-100 inline-flex lg:mt-auto p-3 pl-0"
                        href={el.ctaLink}
                        target={el.ctaOpenNewTab ? '_blank' : ''}
                      >
                        {el.ctaText}
                      </ArrowButton>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
