import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton } from '@truphone/buttons'
import Share from './Share'
import Sidebar from './Sidebar'
import { StructuredText } from 'react-datocms'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { VideoModal } from '@truphone/modals'

function VideoButton(props) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <PrimaryButton
        className={'w-full md:w-auto mt-3 cta cursor-pointer'}
        onClick={() => setOpen(true)}
        title={props.title}
      >
        {props.children}
      </PrimaryButton>
      {open && <VideoModal isOpen={open} href={props.href} handleOpen={(open) => setOpen(open)} />}
    </>
  )
}

export default function UngatedContent(props) {
  return (
    <div className="container mx-auto pt-20 pb-10 px-6 flex justify-between">
      {props.enableSocialSharing && <Share shareTitle={props.shareTitle} />}

      <div className={`w-full max-w-4x ${props.sidebar ? '' : 'mx-auto'} `}>
        {props.pageContent && props.pageContent.value && (
          <div className="text-base p-margin link-blue">
            <StructuredText
              data={props.pageContent}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case 'DatoCmsImage':
                    return (
                      <ImageWrap
                        {...record.image}
                        className={`${
                          record.floatRight
                            ? 'max-w-full float-none md:float-right lg:float-none xl:float-right mb-4 xl:ml-4'
                            : ''
                        }`}
                        style={{
                          width: record.width
                        }}
                      />
                    )
                  case 'DatoCmsCta':
                    return !props.ctaStyle || props.ctaStyle === 'primary' ? (
                      <PrimaryButton
                        className={'w-full md:w-auto my-3 cta'}
                        href={record.href}
                        title={record.title}
                        target={record.openTab && '_blank'}
                      >
                        {record.text}
                      </PrimaryButton>
                    ) : (
                      <SecondaryButton
                        className={'w-full md:w-auto my-3 cta'}
                        href={record.href}
                        title={record.title}
                        target={record.openTab && '_blank'}
                      >
                        {record.text}
                      </SecondaryButton>
                    )

                  case 'DatoCmsVideoCta':
                    return (
                      <VideoButton href={record.href} title={record.title}>
                        {record.text}
                      </VideoButton>
                    )
                  case 'DatoCmsStyledList':
                    return (
                      <div className="pb-4">
                        {record.title && (
                          <p className="text-base font-bold uppercase mb-6">{record.title}</p>
                        )}
                        {record.contentNode && (
                          <div
                            className={`text-base ${
                              record.style === 'green-square-ticks'
                                ? 'list-tickbox-green'
                                : record.style === 'lime-green-square-ticks'
                                ? 'list-tickbox-lime-green'
                                : ''
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: record.contentNode.childMarkdownRemark.html
                            }}
                          ></div>
                        )}
                      </div>
                    )
                  case 'DatoCmsContentHubQuote':
                    return (
                      <>
                        <div className="mt-4 mb-6 flex flex-col items-center sm:flex-row">
                          <div
                            className={`w-44 mr-4 my-4 sm:my-0 rounded-full overflow-hidden shrink-0 ${
                              record.imageRight ? 'sm:hidden' : ''
                            }`}
                          >
                            <ImageWrap className="max-w-full" {...record.image} />
                          </div>

                          <div>
                            {record.title && (
                              <p className="text-base font-bold uppercase mb-6">{record.title}</p>
                            )}
                            {record.content && <p className="text-base italic">{record.content}</p>}
                          </div>

                          {record.imageRight && (
                            <div>
                              <ImageWrap
                                className="ml-4 max-w-full hidden sm:block"
                                {...record.image}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )
                  case 'DatoCmsSpeaker':
                    return (
                      <div className="pb-4">
                        <div className="flex">
                          <div>
                            <ImageWrap className="w-full" {...record.image} />
                          </div>
                          <div className="flex flex-col ml-4">
                            <h3 className="h5-light">{record.fullName}</h3>
                            <p>{record.jobTitle}</p>
                          </div>
                        </div>
                        <p className="mt-4">{record.bio}</p>
                      </div>
                    )
                  default:
                    return null
                }
              }}
            />
          </div>
        )}
      </div>

      {props.sidebar && (
        <div className="hidden lg:block mt-8 lg:mt-0 lg:ml-8 max-w-xs flex-shrink-0">
          <Sidebar sidebar={props.sidebar} />
        </div>
      )}
    </div>
  )
}
