import React, { useRef, useEffect, useState } from 'react'
import { ArrowButton } from '@truphone/buttons'
export default function CardAccordion(props) {
  const content = useRef(null)
  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    function checkHeight() {
      if (window.innerWidth < 960) {
        setMaxHeight(content.current ? content.current.scrollHeight : 1000)
      }
    }

    checkHeight()
    window.addEventListener('resize', checkHeight)

    return () => {
      window.removeEventListener('resize', checkHeight)
    }
  }, [])

  return (
    <div
      ref={content}
      className="transition-all duration-300 rounded-b-lg overflow-hidden"
      style={
        props.selected
          ? {
              maxHeight: maxHeight
            }
          : { maxHeight: '0px' }
      }
    >
      <div className="text-base mt-4" dangerouslySetInnerHTML={{ __html: props.content }} />
      {props.cta && (
        <ArrowButton className={'mt-4 text-blue-100'} href={props.cta.href}>
          {props.cta.text}
        </ArrowButton>
      )}
    </div>
  )
}
