import React from 'react'
import cx from 'classnames'
import { Listbox, Transition } from '@headlessui/react'

const TriangleIcon = (props) => (
  <div
    className={`${props.open ? 'rotate-180' : ''} w-0 h-0`}
    style={{
      borderStyle: 'solid',
      borderWidth: '8px 5.5px 0 5.5px',
      borderColor: '#000000 transparent transparent transparent'
    }}
  ></div>
)

const Selected = () => (
  <svg
    className="h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
    />
  </svg>
)

const getSelectedItemText = (options, value, textAsValue) => {
  return textAsValue
    ? value //options.find((x) => x.text === value).text
    : options.find((x) => x.value === value)?.text
}

const getSelectedItemValue = (option, textAsValue) => {
  return textAsValue ? option.text : option.value
}

const Select = ({
  options,
  value,
  textAsValue,
  onChange,
  onClick,
  onBlur,
  icon,
  className,
  heron,
  isRelative
}) => {
  return (
    <Listbox
      as="div"
      className={cx(
        'w-full ',
        {
          icon: icon || !!value
        },
        className
      )}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {({ open }) => (
        <>
          <div className="relative w-full">
            <Listbox.Button
              onClick={onClick}
              className={`${
                heron
                  ? 'text-4xl leading-none heron text-center py-1'
                  : 'min-h-[3rem] py-3 text-base'
              } ${
                isRelative ? 'flex items-center' : ''
              } rounded-lg  bg-white cursor-default relative z-1 w-full ${
                icon ? 'pl-10' : 'pl-3'
              }  pr-10 text-left transition ease-in-out duration-150`}
            >
              <span className="block">{getSelectedItemText(options, value, textAsValue)}</span>
              <span
                className={`${
                  isRelative ? 'relative' : 'absolute'
                } inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none`}
              >
                <TriangleIcon open={open} />
              </span>
            </Listbox.Button>

            <div className="absolute z-30 w-full mt-1 bg-white rounded-md shadow-lg mb-11 min-w--250">
              {/* bottom-0 will open the select menu up & mb-11 will put the dropup above the select option */}
              <Transition
                show={open}
                leave="transition duration-100 ease-in"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Listbox.Options
                  static
                  className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {options &&
                    options.map((option, i) => {
                      return (
                        <Listbox.Option
                          as={React.Fragment}
                          key={`select-option-${option.id}-${i}`}
                          value={getSelectedItemValue(option, textAsValue)}
                        >
                          {({ active, selected }) => {
                            return (
                              <li
                                className={`${
                                  active ? 'text-black bg-gray-100' : 'text-black'
                                } cursor-pointer select-none relative py-2 px-3`}
                              >
                                <div className="flex items-center justify-between">
                                  <span
                                    className={`${
                                      selected ? 'font-semibold' : 'font-normal'
                                    } flex items-center`}
                                  >
                                    {option.text}
                                  </span>
                                  {selected && (
                                    <>
                                      <span
                                        className={`${
                                          selected ? 'font-semibold' : 'font-normal'
                                        } flex items-center`}
                                      >
                                        {option.label}
                                      </span>

                                      <span
                                        className={`${
                                          active ? 'text-blue-300' : 'text-blue-300'
                                        } inset-y-0 right-0 flex items-center pl-1.5`}
                                      >
                                        <Selected />
                                      </span>
                                    </>
                                  )}
                                </div>
                              </li>
                            )
                          }}
                        </Listbox.Option>
                      )
                    })}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default Select
