import React from 'react'
import { ArrowButton, SecondaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function IconRightBoxes(props) {
  const removeSpaceTopBottom = props.removeSpaceTopBottom

  let wideItem = <></>
  if (props.items.length > 2) {
    let cta = props.items[2].ctas && props.items[2].ctas.length > 0 && props.items[2].ctas[0]
    wideItem = (
      <div className="w-full p-4">
        <a href={cta?.href} target={cta?.openTab ? '_blank' : ''}>
          <div className="bg-white shadow hover:shadow-lg p-8 rounded h-full flex flex-col relative overflow-hidden div-hover">
            <div className="flex relative z-1">
              <div className={`${props.items[2].image ? 'lg:w-2/3' : ''}`}>
                {props.items[2].title && <h3 className="text-2xl">{props.items[2].title}</h3>}
                {props.items[2].content && (
                  <div
                    className="text-base mt-4"
                    dangerouslySetInnerHTML={{ __html: props.items[2].content }}
                  />
                )}
              </div>
              {props.items[2].image && !props.items[2].backgroundGradient && (
                <div className="hidden lg:flex justify-center lg:w-1/3 pl-6">
                  <img className="m-auto" src={props.items[2].image.url} alt="" />
                </div>
              )}
            </div>
            {cta && (
              <div className="w-full flex pt-6 mt-auto">
                <ArrowButton
                  className={`${
                    props.textColor ? 'text-inherit' : 'text-blue-100'
                  } text-lg font-medium`}
                >
                  {cta.text}
                </ArrowButton>
              </div>
            )}
          </div>
        </a>
      </div>
    )
  }

  return (
    <div
      className={`${removeSpaceTopBottom ? '' : 'py-6 lg:py-20'}`}
      style={{
        color: props.textColor?.hex,
        backgroundImage: props.containerBackgroundGradient
          ? props.containerBackgroundGradient
          : null
      }}
    >
      {props.items && (
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row w-full">
            {props.items.slice(0, 2).map((box, i) => {
              const card = (
                <div
                  className={`${
                    box.backgroundGradient ? '' : 'bg-white'
                  } shadow hover:shadow-lg p-8 rounded h-full flex flex-col relative overflow-hidden div-hover`}
                  style={{
                    backgroundImage: box.backgroundGradient || ''
                  }}
                >
                  {box.backgroundGradient && box.image && (
                    <ImageWrap
                      className="div-hover-img absolute top-0 left-0 w-full h-full object-cover transition-all"
                      {...box.image}
                    />
                  )}
                  <div className="flex relative z-1">
                    <div className={`${box.image ? 'lg:w-2/3' : ''}`}>
                      {box.title && <h3 className="text-2xl">{box.title}</h3>}
                      {box.content && (
                        <div
                          className="text-base mt-4"
                          dangerouslySetInnerHTML={{ __html: box.content }}
                        />
                      )}
                    </div>
                    {box.image && !box.backgroundGradient && (
                      <div className="hidden lg:flex justify-center lg:w-1/3 pl-6">
                        <img className="m-auto" src={box.image.url} alt="" />
                      </div>
                    )}
                  </div>
                  {box.ctas?.map((cta) => (
                    <div className="w-full flex pt-6 mt-auto">
                      <ArrowButton
                        className={`${
                          props.textColor ? 'text-inherit' : 'text-blue-100'
                        } text-lg font-medium`}
                        href={cta.href}
                        target={cta.openTab ? '_blank' : null}
                      >
                        {cta.text}
                      </ArrowButton>
                    </div>
                  ))}
                </div>
              )

              return box.ctas.length !== 1 ? (
                <div className="w-full lg:w-1/2 p-4 ">{card}</div>
              ) : (
                <a
                  href={box.ctas[0].href}
                  target={box.ctas[0].openTab ? '_blank' : ''}
                  key={`icon-box-${i}`}
                  className="w-full lg:w-1/2 p-4 "
                >
                  {card}
                </a>
              )
            })}
          </div>
          {wideItem}
        </div>
      )}

      {props.cta && (
        <div className="container mx-auto">
          <div className="flex items-center justify-center w-full py-6">
            <SecondaryButton
              href={props.cta.href}
              target={props.cta.openTab ? '_blank' : null}
              title={props.cta.title}
            >
              {props.cta.text}
            </SecondaryButton>
          </div>
        </div>
      )}
    </div>
  )
}
