import React from 'react'
import { uppercaseString } from '@truphone/utils'
import { OutlinedButtonBlack, PrimaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { Arrow, ArrowSimple } from './Icons'
import { CarouselProvider, Slider, Slide, Dot, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

function ImageCarousel(props) {
  return (
    <CarouselProvider
      infinite={true}
      naturalSlideWidth={536}
      naturalSlideHeight={285}
      isIntrinsicHeight={true}
      totalSlides={props.images?.length}
      isPlaying={!props.cta}
    >
      <Slider>
        {props.images?.map((item, i) => {
          return (
            <Slide
              index={i}
              className="w-full p-0"
              innerClassName="flex justify-center items-center"
              key={`carousel-item-${i}`}
            >
              <ImageWrap className="object-contain w-full my-auto" objectFit="contain" {...item} />
            </Slide>
          )
        })}
      </Slider>

      {props.cta && (
        <ButtonNext
          href={props.cta.href}
          className="font-semibold text-lg p-4 bg-white hover:bg-grey-400 rounded-lg flex items-center mx-auto"
        >
          {props.cta.text}
          <ArrowSimple className={`w-4 h-4 -rotate-90 ml-4`} />
        </ButtonNext>
      )}

      {!props.cta && props.images && (
        <div className="mt-2 flex justify-center">
          {props.images.map((_item, i) => {
            return (
              <Dot
                className="w-3 h-3 m-2 flex justify-center border border-black hover:bg-black hover:opacity-75 rounded-full items-center dot-hover"
                key={`slide-dot-${i}`}
                slide={i}
              ></Dot>
            )
          })}
        </div>
      )}
    </CarouselProvider>
  )
}

export default function ImageCarouselTextBlock(props) {
  const cover = props.imageStyle === 'cover'
  const linkStyle = props.ctaStyle === 'link'
  const primaryStyle = props.ctaStyle === 'primary'
  return (
    <div
      className={`${cover ? '' : 'py-10 lg:py-20'} relative`}
      style={{
        backgroundColor: props.backgroundColor && props.backgroundColor.hex,
        color: props.textColor && props.textColor.hex
      }}
    >
      <div
        className={`flex items-center relative ${
          props.imageRight ? 'flex-col-reverse lg:flex-row-reverse' : 'flex-col lg:flex-row'
        }  ${
          props.backgroundImage || props.large || props.imageActualSize
            ? 'container'
            : cover && props.imageRight
            ? 'flex-col-reverse lg:justify-start'
            : cover
            ? 'lg:justify-end'
            : 'lg:max-w-5xl'
        } ${cover ? '' : 'px-7 md:px-10 lg:px-4'} ${props.paddingTopBottom ? 'py-12' : ''} mx-auto`}
      >
        <div
          className={`${cover ? '' : props.imageRight ? 'mt-9 lg:mt-0' : 'mb-9 lg:mb-0'} ${
            props.backgroundImage || cover ? 'lg:w-1/2 ' : ''
          } w-full  relative
          ${props.imageBackgroundColor ? 'pb-12 lg:pb-0' : ''}
          ${
            props.carouselImages
              ? 'flex flex-col items-center'
              : cover
              ? ''
              : 'rounded-2xl overflow-hidden'
          }`}
        >
          {props.carouselImages && props.carouselImages.length > 0 && (
            <ImageCarousel images={props.carouselImages} cta={props.cta} />
          )}
        </div>
        <div
          className={`${props.imageRight ? 'lg:pr-12' : 'lg:pl-12'} ${
            props.backgroundImage || cover ? 'lg:w-1/2 max-w-xl' : ''
          } ${props.large || props.imageActualSize ? 'w-full' : ''}
          ${cover ? 'py-10 px-4 lg:py-3 lg:pl-12' : ''}`}
        >
          {props.topTitle && (
            <p
              className={`overline-text mb-4 ${props.oneText ? 'text-white' : ''}`}
              style={{
                color: props.textColor && props.textColor.hex
              }}
            >
              {uppercaseString(props.topTitle)}
            </p>
          )}
          {props.title && <h3 className="h4-normal mb-6">{props.title}</h3>}
          {props.content && (
            <div
              className={`text-base ${
                props.oneText
                  ? 'text-white list-tick list-tick-green'
                  : 'list-square list-item-spacing'
              } p-margin`}
              dangerouslySetInnerHTML={{ __html: props.content }}
              style={{
                color: props.textColor && props.textColor.hex
              }}
            />
          )}

          {props.footer && (
            <div
              className="text-sm mt-6 list-square list-item-spacing"
              dangerouslySetInnerHTML={{ __html: props.footer }}
            />
          )}
          {props.cta && props.carouselImages?.length === 0 && (
            <>
              {linkStyle ? (
                <a
                  href={props.cta.href}
                  className={`mt-8 ${
                    props.backgroundColor && props.backgroundColor.hex === '#070158'
                      ? 'text-blue-100'
                      : ''
                  } text-lg font-bold flex items-center hover-arrow`}
                  style={{
                    color:
                      props.backgroundColor && props.backgroundColor.hex === '#070158'
                        ? ''
                        : props.textColor && props.textColor.hex
                  }}
                >
                  {props.cta.text}
                  <Arrow className={`ml-3 w-4 h-4 -rotate-90 icon-arrow transition-transform`} />
                </a>
              ) : primaryStyle || props.videoUrl ? (
                <PrimaryButton className={`mt-8`} href={props.cta.href}>
                  {props.cta.text}{' '}
                </PrimaryButton>
              ) : (
                <OutlinedButtonBlack href={props.cta.href} className="mt-8">
                  {props.cta.text}
                </OutlinedButtonBlack>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
