import { SecondaryButton } from '@truphone/buttons'
import React, { useState } from 'react'
import IconCard from './IconCard'
import CardAccordion from './CardAccordion'
import { ArrowSimple } from '@truphone/icons'

export default function IconsBlock(props) {
  const [selected, setSelected] = useState(-1)
  const mediumIcons = props.style === 'medium-icons'
  const iconTitle = props.style === 'icon-title'
  const smallIconSmallTitle = props.style === 'small-icon-small-title'
  return (
    <div
      className={`py-10 ${iconTitle ? 'lg:pb-0' : props.smallPaddingTopBottom ? '' : 'lg:py-20'}`}
      style={{
        backgroundColor: props.backgroundColor?.hex,
        color: props.textColor?.hex
      }}
    >
      <div className={`container mx-auto ${props.large ? 'px-10' : 'px-6'}`}>
        {props.iconBlocks && (
          <div
            className={`flex ${props.large ? 'flex-col lg:flex-row' : 'flex-wrap'} ${
              props.centered || props.imageWithTitle ? 'justify-center' : ''
            }`}
            style={{ color: props.textColor?.hex }}
          >
            {props.iconBlocks.map((icon, i) => {
              return (
                <>
                  <div
                    key={`icons-${i}`}
                    className={`w-full ${
                      props.large
                        ? 'max-w-sm text-center lg:px-6 mx-auto'
                        : props.borderLeft
                        ? 'hidden lg:block pr-8 pb-2 md:w-1/2 lg:w-1/3 mb-8'
                        : props.centered || props.imageWithTitle
                        ? 'p-4 md:pb-10 lg:pb-14'
                        : 'p-4 md:pb-10 lg:pb-14'
                    } ${props.large && i > 0 ? 'mt-20 lg:mt-0' : ''} ${
                      props.iconBlocks.length % 4 === 0
                        ? 'max-w-sm mx-auto p-4 lg:px-6 sm:w-1/2 lg:w-1/4 '
                        : 'sm:p-4 md:py-10 lg:py-14 md:w-1/2 lg:w-1/3'
                    }`}
                  >
                    <IconCard
                      {...icon}
                      centered={props.centered || props.imageWithTitle}
                      large={props.large}
                      smallIconSmallTitle={smallIconSmallTitle}
                      mediumIcons={mediumIcons}
                      iconTitle={iconTitle}
                      iconCenter={props.centered}
                      textCenter={props.centered}
                      borderLeft={props.borderLeft}
                      highlightColor={props.highlightColor}
                      imageWithTitle={props.imageWithTitle}
                      leftAligned={props.leftAligned}
                      selected={selected === i}
                    />
                  </div>
                  <div className={`w-full mb-6 ${props.borderLeft ? 'block lg:hidden' : 'hidden'}`}>
                    <div
                      className="cursor-pointer flex justify-between items-center"
                      onClick={() => (selected === i ? setSelected(null) : setSelected(i))}
                    >
                      <div className="flex items-center">
                        {icon.image && (
                          <div className={`h-6 shrink-0 mr-2`}>
                            <img
                              className={`h-full block`}
                              src={icon.image.url}
                              alt={icon.image.alt}
                            />
                          </div>
                        )}
                        <h3 className="text-lg sm:text-2xl font-light">{icon.topTitle}</h3>
                      </div>
                      <span className={`pr-1 opacity-50`}>
                        <ArrowSimple
                          className={`w-4 h-4  ${selected === i ? 'rotate-180' : ''}`}
                        ></ArrowSimple>
                      </span>
                    </div>
                    <CardAccordion selected={selected === i} {...icon} />
                  </div>
                </>
              )
            })}
          </div>
        )}
        {props.cta && (
          <div className="flex justify-center">
            <SecondaryButton
              targer={props.cta.openTab ? '_blank' : ''}
              href={props.cta.href}
              className="md:mt-4 text-center mx-auto"
            >
              {props.cta.text}
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  )
}
