import React, { useState } from 'react'
import TabsImages from './TabsImages'
import ItemsGroup from './ItemsGroup'

function TabsContainer(props) {
  const [selected, setSelected] = useState(0)
  const cta = props.cta.length > 0 ? props.cta[0] : null
  return (
    <>
      <TabsImages
        title={props.title}
        tabs={
          props.tabs &&
          props.tabs.map((tab) => ({
            title: tab.title,
            image: {
              url: props.image?.url || tab.image?.url,
              alt: props.image?.alt || tab.image?.alt,
              gatsbyImageData: props.image?.gatsbyImageData || tab.image?.gatsbyImageData
            },
            video: props.video?.url || tab.video?.url
          }))
        }
        onSelectedChanged={(i) => setSelected(i)}
      />
      <div className="container mx-auto px-6 pt-6 pb-16">
        {props.tabs &&
          props.tabs.map((item, i) => {
            return (
              <div key={`tab-content-${i}`} className={`${selected === i ? 'block' : 'hidden'}`}>
                <div className="flex flex-wrap md:flex-nowrap lg:mt-7">
                  <ItemsGroup key={`content-${i}`} items={item.content} />
                </div>
              </div>
            )
          })}
        {cta && (
          <div className="mt-5 lg:mt-14 text-center">
            <a
              className="w-full sm:w-auto bg-blue-100 min-w--250 inline-block text-white text-xl font-semibold mx-auto py-3 px-10 rounded-lg"
              href={cta.href}
            >
              {cta.text}
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default TabsContainer
