import React from 'react'
import { Arrow } from '@truphone/icons'
import './card-bgimage.css'
import { VideoModal } from '@truphone/modals'

export default function CardSideImage(props) {
  return props.video ? (
    <CardSide {...props} />
  ) : (
    <a
      className="h-full block"
      href={props.cta && props.cta.href}
      title={props.cta && props.cta.title}
      target={props.cta && props.cta.openTab ? '_blank' : ''}
    >
      {props.children}
      <CardSide {...props} />
    </a>
  )
}

function CardSide(props) {
  return (
    <div
      className={`${
        props.background === 'darkblue'
          ? 'bg-blue-900 text-white'
          : props.background === 'lightblue'
          ? 'bg-blue-100 text-white'
          : props.background === 'green'
          ? 'bg-green-100 text-black'
          : 'bg-white text-black'
      } flex flex-col 
      ${props.large ? 'sm:flex-row' : 'lg:flex-row'}
      ${props.video ? 'cursor-pointer' : ''}
       h-full hover-effect`}
      style={{
        background: props.backgroundColor && props.backgroundColor.hex,
        color: props.textColor && props.textColor.hex
      }}
    >
      <div
        className={`relative h-inherit w-full  ${
          props.large ? 'sm:w-1/2' : 'lg:w-1/2'
        }  overflow-hidden`}
      >
        <div
          className={`w-full h-full bg-cover bg-center  ${
            props.large ? 'card-image-side' : 'card-image-small'
          }`}
          style={{
            backgroundImage: props.image ? `url(${props.image.url})` : ''
          }}
        ></div>
        {/* {props.image && <img className="w-full" src={props.image.src} alt={props.image.alt} />} */}
        {props.video && (
          <VideoModal isVideo={true} href={props.video.url}>
            <div className="absolute top-0 left-0 text-white w-full h-full flex justify-center items-center">
              <img src="/images/icons/icon-action-play-play-white.svg" alt="play" />
            </div>
          </VideoModal>
        )}
      </div>
      <div
        className={`w-full ${
          props.large ? 'sm:w-1/2 sm:pr-12' : 'lg:w-1/2 lg:pr-12'
        } px-6 py-6 flex flex-col `}
      >
        {props.tag && (
          <h5
            className={`mr-auto uppercase tracking-widest font-bold py-1 px-3 rounded mb-6 text-black inline-block`}
            style={{
              background: props.tagColor && props.tagColor.hex,
              color: props.tagTextColor && props.tagTextColor.hex
            }}
          >
            {props.tag}
          </h5>
        )}
        <h3 className="text-lg md:text-xl mb-6">{props.title}</h3>
        {props.content && (
          <div
            className={`text-base leading-normal`}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        )}
        {props.cta && (
          <span className="arrow-link flex items-center mt-auto pt-14">
            <p className="text-base mr-3">{props.cta.text}</p>
            <Arrow
              className={`${
                props.background === 'darkblue' ? 'text-green-100' : 'text-inherit'
              } w-4 h-4 -rotate-90 arrow`}
            />
          </span>
        )}
      </div>
    </div>
  )
}
