import React from 'react'

export default function Stats(props) {
  return (
    <div
      className={`pt-4 ${props.smallPaddingBottom ? 'pb-4' : 'pb-10 lg:pb-20 '} overflow-hidden`}
      style={{ background: props.backgroundColor, color: props.textColor }}
    >
      {props.stats && (
        <div className="container mx-auto px-4">
          <div className="justify-center flex flex-wrap -mx-8">
            {props.stats.map((stat, i) => {
              return (
                <div
                  key={`stat-${i}`}
                  className={`text-center lg:text-left py-4 px-8 w-full sm:w-1/2 ${
                    props.stats.length % 3 === 0
                      ? 'lg:w-1/3'
                      : props.stats.length % 4 === 0
                      ? 'lg:w-1/4'
                      : ''
                  }`}
                >
                  <div className={`max-w-xs mx-auto ${i % 2 === 0 ? 'md:mr-0' : 'md:ml-0'}`}>
                    {stat.image && (
                      <img
                        className="mx-auto lg:mx-0 mb-4"
                        src={stat.image.src}
                        alt={stat.image.alt}
                      />
                    )}
                    <h3 className={`text-5xl md:text-6xl heron mb-4 whitespace-nowrap`}>
                      {stat.title}
                    </h3>
                    {stat.text && (
                      <p className={`text-base font-light`} style={{ color: props.contentColor }}>
                        {stat.text}
                      </p>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
