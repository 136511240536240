import React, { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Tooltip from './Tooltip'
import './slider.css'
import loadable from '@loadable/component'

const ReactSlider = loadable.lib(() => import('react-slider'))

export default function Slider(props) {
  const { control } = useFormContext()

  const [currentValue, setCurrentValue] = useState(props.selectFieldOptions[0])
  const [badgeText, setBadgeText] = useState('')
  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    setTooltipText(
      currentValue
        ? (props.tooltipFormat || '{label}')
            .replace('{label}', currentValue.text)
            .replace('{value}', currentValue.value)
        : ''
    )

    setBadgeText(
      currentValue
        ? (props.badgeFormat || '{label}')
            .replace('{label}', currentValue.text)
            .replace('{value}', currentValue.value)
        : ''
    )
  }, [currentValue])

  const itemCount = props.selectFieldOptions.length

  return (
    <div>
      <div className="inline-flex relative mb-6 pr-16">
        <h4 className="text-sm label-tooltip">
          {props.label} {props.required ? ' *' : ''}
        </h4>
        {props.tooltip && <Tooltip tooltip={props.tooltip} type={props.type} />}
      </div>
      <Controller
        name={props.name}
        control={control}
        render={({ field: { onChange } }) => (
          <div className="flex flex-nowrap items-start justify-between mb-4">
            {props.icon && (
              <div className="relative w-6 -mt-2">
                <img src={props.icon.url} alt="" className="w-5 select-none" />
              </div>
            )}
            <div className={`relative ${props.icon ? 'slider-width' : 'w-4/5'}`}>
              <ReactSlider
                className="w-full h-6"
                marks={1}
                markClassName="slider-mark w-1 h-1 bg-white rounded-full"
                step={1}
                min={0}
                max={itemCount - 1}
                onChange={(newValue) => {
                  const selectValue = props.selectFieldOptions[newValue]
                  setCurrentValue(selectValue)
                  onChange(selectValue.value)
                }}
                thumbClassName="thumb-hover relative outline-none w-5 h-5 bg-blue-100 text-white p-1 text-xs text-center cursor-pointer rounded-full -ml-2 -mt-2"
                trackClassName="track-bg"
                renderThumb={(props) => (
                  <div className="relative z-2" {...props}>
                    <div className="w-full h-full hover-value">
                      <span className="value-label text-center whitespace-nowrap absolute -bottom-10 left-1/2 -translate-x-1/2 bg-blue-100 py-1 px-3 min-w-50 rounded-lg text-xs text-white">
                        {tooltipText}
                      </span>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className={`relative ${props.icon ? 'w-28' : 'w-1/5 pl-4'} -mt-3`}>
              <div className="w-full text-center whitespace-nowrap bg-blue-100 py-1 px-3 min-w-50 rounded text-sm text-white">
                {badgeText}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}
