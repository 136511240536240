import React from 'react'
import { ArrowButton } from '@truphone/buttons'

export default function IconCard(props) {
  return (
    <>
      <div
        className={`${props.borderLeft ? 'border-l-2 border-gray-300 pl-8' : ''} ${
          props.centered | props.mediumIcons
            ? 'text-center'
            : props.leftAligned
            ? 'flex'
            : props.smallIconSmallTitle
            ? 'flex flex-row sm:flex-col sm:text-center items-center my-2'
            : ''
        }`}
      >
        {props.topTitle && <p className="overline-bold uppercase mb-2">{props.topTitle}</p>}
        <div
          className={`${
            props.iconTitle
              ? 'flex flex-col sm:flex-row items-start sm:items-center'
              : props.leftAligned
              ? 'mr-4 shrink-0'
              : props.smallIconSmallTitle
              ? 'shrink-0'
              : ''
          }`}
        >
          {props.image && (
            <div
              className={`shrink-0 ${
                props.large
                  ? 'h-20 mb-4 lg:mb-10'
                  : props.mediumIcons
                  ? 'h-16 mx-auto w-32 mb-4 flex justify-center'
                  : props.smallIconSmallTitle
                  ? ''
                  : props.iconTitle
                  ? 'h-6 w-6 mr-4 mb-4 flex justify-center'
                  : props.centered
                  ? 'flex justify-center mb-4'
                  : 'mb-4'
              } 
            ${props.centered ? 'text-center' : ''}`}
            >
              <img
                className={`${
                  props.large || props.centered
                    ? 'mx-auto h-full'
                    : props.iconTitle
                    ? 'mb-2 sm:mb-0 sm:mr-3'
                    : props.smallIconSmallTitle
                    ? 'mx-auto mr-4 sm:mr-0 sm:mb-5'
                    : ''
                } ${
                  props.mediumIcons || props.iconTitle || props.borderLeft ? 'h-full' : ''
                }  block`}
                src={props.image.url}
                alt={props.image.alt}
              />
            </div>
          )}
          {props.title && !props.leftAligned && !props.smallIconSmallTitle && (
            <h3
              className={`${
                props.large
                  ? 'text-xl font-medium pb-4 border-b border-gray-200'
                  : props.iconTitle
                  ? 'font-semibold mb-4 leading-tight'
                  : props.imageWithTitle
                  ? 'text-xl mb-10'
                  : 'text-2xl'
              } mb-2`}
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
          )}
        </div>
        {props.content && (
          <div>
            {(props.smallIconSmallTitle || props.leftAligned) && props.title && (
              <h3
                className={`leading-tight font-semibold text-base mb-2`}
                dangerouslySetInnerHTML={{ __html: props.title }}
              />
            )}
            <div
              className={`${
                props.iconTitle ? 'text-[13px]' : props.centered ? 'text-base' : 'text-sm'
              } link-blue mb-3 ${props.highlightColor === 'lime-green' ? 'green-b-tag' : ''}`}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          </div>
        )}

        {props.cta && (
          <ArrowButton
            className={`mt-auto text-blue-100 ${props.centered ? 'justify-center' : ''}`}
            href={props.cta.href}
          >
            {props.cta.text}
          </ArrowButton>
        )}
      </div>
    </>
  )
}
