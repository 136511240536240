import React, { useState, useEffect } from 'react'

export default function SnackBox(props) {
  const [showSnackBox, setShowSnackBox] = useState(false)
  useEffect(() => {
    const shown = localStorage.getItem(`snackbox_${props.name}_shown`)
    if (shown != '1') {
      setShowSnackBox(true)
    }
  }, [])

  const closeSnackbox = () => {
    localStorage.setItem(`snackbox_${props.name}_shown`, '1')
    setShowSnackBox(false)
  }

  const clickOnSnackBox = (e) => {
    const links = e.target.getElementsByTagName('a')
    if (links && links.length > 0) {
      links[0].click()
    }
  }

  return showSnackBox ? (
    <div className="relative z-2 -mt-10">
      <div className="container mx-auto p-2">
        <div
          onClick={(e) => clickOnSnackBox(e)}
          className="cursor-pointer hover:shadow-md mb-10 py-3 px-4 rounded-xl flex justify-between items-center link-underline font-normal"
          style={{
            background: props.backgroundColor ? props.backgroundColor.hex : '#4156cb',
            color: props.textColor ? props.textColor.hex : '#ffffff'
          }}
        >
          <div
            className="text-base font-light w-full"
            dangerouslySetInnerHTML={{ __html: props.text }}
          />
          <span onClick={() => closeSnackbox()} className="w-6 ml-4 cursor-pointer text-2xl">
            ✕
          </span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
