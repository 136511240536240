import React from 'react'
import { HeadingH2, HeadingH3Light } from './Typography'
import cx from 'classnames'

export default function Title(props) {
  return (
    <div className={cx('container mx-auto px-4 text-center', props.className)}>
      <HeadingH2 className="mb-3">{props.title}</HeadingH2>
      <HeadingH3Light className="leading-normal">{props.subtitle}</HeadingH3Light>
    </div>
  )
}
