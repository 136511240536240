import React from 'react'
import { Arrow } from './Icons'
import { HeadingH3Light, HeadingH3Small } from './Typography'

export default function CardBgImage(props) {
  return (
    <div className="flex flex-wrap bg-gray-100">
      {props.items && (
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap lg:flex-nowrap -mx-4">
            {props.items.map((el, i) => {
              return (
                <div key={`sideimage-${i}`} className="p-4 w-full lg:w-1/2">
                  <a
                    href={el.cta.href}
                    className="h-full flex flex-col bg-cover p-8 lg:p-14 bg-blue-900 text-white rounded-lg overflow-hidden"
                    style={{
                      backgroundImage: el.backgroundImage ? `url(${el.backgroundImage})` : ''
                    }}
                  >
                    <h5 className="mr-auto uppercase tracking-widest font-bold py-1 px-3 rounded bg-green-100 text-black inline-block">
                      {el.tag}
                    </h5>
                    <HeadingH3Small className="mt-6 mb-4">{el.title}</HeadingH3Small>
                    <span className="flex mt-auto">
                      <HeadingH3Light className="mr-4">{el.cta.text}</HeadingH3Light>
                      <Arrow className="w-5 -rotate-90" />
                    </span>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
