import { PrimaryButton, SecondaryButton } from '@truphone/buttons'
import './form.css'
import React, { useState } from 'react'
import { Disable } from 'react-disable'
import { useForm, FormProvider } from 'react-hook-form'
import { submitForm } from './marketo'
import PrivacyPolicy from './PrivacyPolicy'
import EmailField from './EmailField'
import FieldWrapper from './FieldWrapper'
import Cookies from 'universal-cookie'
import { YouTube, Vimeo, LinkedIn } from '@truphone/icons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

const cookies = new Cookies()

function getDefaultValues(fields, defaults) {
  let returnValue = {}
  fields
    .filter((x) => !!x)
    .forEach((x) => {
      if (defaults && defaults[x.name]) {
        returnValue[x.name] = defaults[x.name]
      } else {
        returnValue[x.name] = ''
      }
    })
  return returnValue
}

export default function Form(props) {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [existingLead, setExistingLead] = useState(false)
  const [progressiveFields, setProgressiveFields] = useState([])

  const defaultValues = getDefaultValues(
    [props.emailField, ...(props.requiredFields || []), ...(props.progressiveFields || [])],
    props.defaultValues
  )

  const methods = useForm({ mode: 'onBlur', criteriaMode: 'all', defaultValues })

  const submit = async (data) => {
    setSubmitting(true)
    await submitForm(props, data)
    setSubmitting(false)
    setSubmitted(true)

    if (props.onSubmitted) {
      props.onSubmitted()
    }
  }

  const hasProgressiveFields = progressiveFields && progressiveFields.length > 0
  return (
    <>
      {submitted ? (
        <>
          {!props.enableAdvancedThankYouMessage ? (
            <div
              className="text-base link-underline"
              dangerouslySetInnerHTML={{ __html: props.thankYouText }}
            />
          ) : (
            <div>
              <ImageWrap className="mx-auto py-8" {...props.thankYouImage} />
              <h4 className="h4-medium text-center py-4">{props.thankYouTitle}</h4>
              <div
                className="body1-muted text-center whitespace-pre-wrap pb-4"
                dangerouslySetInnerHTML={{ __html: props.thankYouContent }}
              />
              {props.enableSocialIcons && (
                <>
                  <p className="overline-muted text-center mt-14 mb-2 uppercase">
                    {props.socialIconsTitle}
                  </p>
                  <div className="flex justify-between max-w-sm mx-auto brightness-100 grayscale border-t border-gray-200 pt-4">
                    {/* <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://www.facebook.com/truphone"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <Facebook />
                    </a> */}
                    {/* <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://twitter.com/truphone"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <Twitter />
                    </a> */}
                    {/* <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://medium.com/@Truphone"
                      title="Medium"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <Medium />
                    </a> */}
                    <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://www.linkedin.com/company/truphone"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <LinkedIn />
                    </a>
                    <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://www.youtube.com/user/truphone"
                      title="YouTube"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <YouTube />
                    </a>
                    <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://vimeo.com/truphone"
                      title="Vimeo"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <Vimeo />
                    </a>
                    {/* <a
                      className="w-6 m-3 opacity-40 hover:opacity-100"
                      href="https://www.instagram.com/mytruphone"
                      title="Instagram"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <Instagram />
                    </a> */}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <FormProvider {...methods}>
          <Disable disabled={submitting}>
            <form
              autoComplete="off"
              className="relative flex flex-wrap -mx-2 mb-0"
              onSubmit={methods.handleSubmit(submit, (errors) => console.log(errors))}
            >
              {/* Badge Text */}
              {/* Title */}
              {/* Welcome / Welcome back text */}
              {props.emailField && (
                <EmailField
                  existingLead={existingLead}
                  notYouText={props.notYouText}
                  onClear={() => {
                    cookies.remove('MarketoLeadUniqueId', { path: '/' })
                    methods.reset(defaultValues)
                    setExistingLead(false)
                    setProgressiveFields([])
                  }}
                  {...props.emailField}
                />
              )}

              {!hasProgressiveFields &&
                props.requiredFields &&
                props.requiredFields.map((el, i) => {
                  return <FieldWrapper key={`required-field-${i}`} {...el} />
                })}

              {hasProgressiveFields &&
                progressiveFields.map((el, i) => {
                  return <FieldWrapper key={`progressive-field-${i}`} {...el} />
                })}

              <PrivacyPolicy
                {...Object.fromEntries(
                  new Map(
                    Object.getOwnPropertyNames(props)
                      .filter((x) => x.startsWith('legal'))
                      .map((x) => [x, props[x]])
                  )
                )}
              />

              {props.renderAttachments && typeof props.renderAttachments === 'function' && (
                <>{props.renderAttachments()}</>
              )}

              <div className={`w-full p-2 ${props.centerCta ? 'text-center' : ''}`}>
                {props.secondaryCta ? (
                  <SecondaryButton
                    className={`w-full ${
                      props.fullWidthCta ? '' : 'md:w-auto'
                    } mt-6 cursor-pointer`}
                    component="button"
                    type="submit"
                  >
                    {props.ctaText}
                  </SecondaryButton>
                ) : (
                  <PrimaryButton
                    className={`w-full ${
                      props.fullWidthCta ? '' : 'md:w-auto'
                    }  mt-6 cursor-pointer`}
                    component="button"
                    type="submit"
                  >
                    {props.ctaText}
                  </PrimaryButton>
                )}
              </div>
              <div
                className="mt-5 text-xs link-underline"
                dangerouslySetInnerHTML={{ __html: props.disclaimerText }}
              ></div>
            </form>
          </Disable>
        </FormProvider>
      )}
    </>
  )
}
