import React from 'react'
import Field from './Field'

function EmailField(props) {
  return (
    <div className={`relative w-full p-2 ${props.short ? 'md:w-1/2' : ''}`}>
      <Field disabled={props.existingLead} {...props} />
      {props.existingLead && (
        <a className="cursor-pointer text-blue-100 text-xs hover:underline" onClick={props.onClear}>
          {props.notYouText}
        </a>
      )}
    </div>
  )
}

export default EmailField
