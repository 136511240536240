import React, { useRef } from 'react'
import { uppercaseString } from '@truphone/utils'
import { ArrowSimple } from '@truphone/icons'

function Accordion(props) {
  const content = useRef(null)

  return (
    <div className={`${props.languages ? '' : 'border-b border-white border-opacity-30'}`}>
      <button
        className={`flex w-full ${
          props.footer || props.faqsList ? 'py-2' : props.flag ? 'py-1' : 'py-3'
        } text-left`}
        onClick={props.onChange}
      >
        {props.footer ? (
          <>
            <p className="text-base uppercase font-bold tracking-wider">
              {uppercaseString(props.title)}
            </p>
            <svg
              className={`text-sm ml-auto inline-block transform transition-all ${
                props.expanded ? 'rotate-90' : 'rotate-0'
              }`}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              fill="currentColor"
              style={{ width: 12, height: 18 }}
            >
              <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path>
            </svg>
          </>
        ) : props.mainItem ? (
          <div className="w-full flex items-center justify-between">
            <div className="mt-2 pr-4">
              <div className="flex">
                {(props.imgUrl || props.imgWhiteUrl) && (
                  <img className="mr-2" src={props.imgWhiteUrl || props.imgUrl} alt="" />
                )}
                {props.title && <h5 className="overline-text">{uppercaseString(props.title)}</h5>}
              </div>
              <p className="text-xs mt-2 mb-2">{props.subtitle}</p>
            </div>
            <ArrowSimple className={`w-3 transform ${props.expanded ? 'rotate-180' : ''}`} />
          </div>
        ) : props.faqsList ? (
          <h3
            className={`accordion__title ${
              props.expanded && !props.sideComponent ? 'text-blue-100' : 'text-black'
            } text-xl font-medium w-full flex items-center justify-between `}
          >
            {props.title}

            <span className="text-black text-2xl"> {props.expanded ? '-' : '+'} </span>
          </h3>
        ) : props.flag ? (
          <div className="w-full flex items-center justify-between bg-white p-2 rounded">
            <div className="flex">
              <img
                className="mr-2 w-6"
                src={`/images/flags/${props.flag.toLowerCase()}.svg`}
                alt={props.flag}
              />

              <p className="text-sm">{props.title}</p>
            </div>

            <ArrowSimple className={`w-3 transform ${props.expanded ? 'rotate-180' : ''}`} />
          </div>
        ) : (
          <p
            className={`accordion__title ${
              props.items ? 'text-sm w-full flex items-center justify-between' : ''
            }`}
          >
            {props.title ? props.title : props.text}{' '}
            {props.items && (
              <ArrowSimple className={`w-3 transform ${props.expanded ? 'rotate-180' : ''}`} />
            )}{' '}
          </p>
        )}
      </button>
      <div
        ref={content}
        style={
          props.expanded
            ? {
                maxHeight: 1000 //content.current ? content.current.scrollHeight :
              }
            : { maxHeight: '0px' }
        }
        className={`overflow-hidden transition-all ${
          props.faqsList && props.expanded ? 'my-4' : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  )
}

export default Accordion
