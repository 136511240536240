import React, { useState } from 'react'

function Tooltip(props) {
  const [tooltip, setTooltip] = useState(false)

  return (
    <div
      className={`${props.isRelative ? 'relative -mt-6 lg:-mt-3' : 'absolute'} w-full ${
        props.type === 'Select' ? 'right-4' : 'right-0'
      } ${props.type === 'Date' ? 'top-0 right-7' : 'top-1 '} z-2`}
    >
      <svg
        className={`absolute top-2 right-2
         w-6 text-black opacity-50 hover:text-blue-900 hover:opacity-100`}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        onMouseEnter={() => setTooltip(true)}
        onMouseLeave={() => setTooltip(false)}
      >
        <path
          fill="currentColor"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        ></path>
      </svg>

      <div
        className={`${
          tooltip ? 'scale-100 h-auto opacity-100' : 'opacity-0 scale-x-50 scale-y-0 h-0'
        } tooltip-text transition-all duration-300 absolute min-w-[150px] max-w-xs right-0 bottom-0`}
      >
        <h4 className="text-xs text-white bg-blue-900 inline-block py-1 px-2 rounded">
          {props.tooltip}
        </h4>
      </div>
    </div>
  )
}

export default Tooltip
