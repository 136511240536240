function uppercaseString(string) {
  return string
    ? string
        .toUpperCase()
        .replace('ESIM', 'eSIM')
        .replace('ISIM', 'iSIM')
        .replace('IOT', 'IoT')
        .replace('IPAD', 'iPAD')
        .replace('IPHONE', 'iPHONE')
        .replace('MIFID', 'MiFID')
        .replace('WIFI', 'WiFi')
        .replace('IBAHN', 'iBAHN')
    : null
}

function supportsVideoType(type) {
  if (typeof document !== 'undefined') {
    let video

    // Allow user to create shortcuts, i.e. just "webm"
    let formats = {
      ogg: 'video/ogg; codecs="theora"',
      h264: 'video/mp4; codecs="avc1.42E01E"',
      webm: 'video/webm; codecs="vp8, vorbis"',
      vp9: 'video/webm; codecs="vp9"',
      hls: 'application/x-mpegURL; codecs="avc1.42E01E"'
    }

    if (!video) {
      video = document.createElement('video')
    }

    return video.canPlayType(formats[type] || type)
  }

  return false
}

const geoLookup = async () => {
  let geo = sessionStorage.getItem('geo') && JSON.parse(sessionStorage.getItem('geo'))
  if (geo) {
    return geo
  }

  const response = await fetch('https://web.truphone.com/api/geo/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  })

  if (response.ok) {
    const json = await response.json()
    sessionStorage.setItem('geo', JSON.stringify(json))
    return json
  }
}

export { uppercaseString, supportsVideoType, geoLookup }
