import React from 'react'
import { useFormContext } from 'react-hook-form'

function RadioButton(props) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  const rules = {}

  if (props.required) {
    rules.required = { value: true, message: props.requiredMessage }
  }

  return (
    <div className="ml-2 my-4 w-full relative">
      <h4 className="text-sm mb-2">
        {props.label} {props.required && ' *'}
      </h4>
      <label className="my-1 mr-4 inline-flex items-center">
        <input
          className="text-blue-100 form-checkbox rounded-full"
          type="radio"
          value={true}
          name={props.name}
          {...register(props.name, rules)}
        />
        <span
          className="ml-2 relative inline-flex link-underline text-sm text-black"
          dangerouslySetInnerHTML={{ __html: props.yesLabel }}
        />
      </label>
      <label className="my-1 mr-4 inline-flex items-center">
        <input
          className="text-blue-100 form-checkbox rounded-full"
          type="radio"
          value={false}
          name={props.name}
          {...register(props.name, rules)}
        />
        <span
          className="ml-2 relative inline-flex link-underline text-sm text-black"
          dangerouslySetInnerHTML={{ __html: props.noLabel }}
        />
      </label>
      {errors[props.name] && (
        <span className="relative text-xs text-pink">{errors[props.name].message}</span>
      )}
    </div>
  )
}

export default RadioButton
