import Cookies from 'universal-cookie'

const cookies = new Cookies()

const getDataSource = () => {
  let documentReferrer
  if (document.referrer.indexOf('?') > 0) {
    documentReferrer = document.referrer.substring(0, document.referrer.indexOf('?'))
  } else {
    documentReferrer = document.referrer
  }
  if (!document.referrer) {
    documentReferrer = '[none]'
  }
  return documentReferrer
}

const postPageViewToGoogle = (data) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'apiFormSubmit',
    formId: data['Lead_Source_Description__c'],
    leadSource: data['leadSource'],
    leadSourceOther: data['Lead_Source_Description__c'],
    leadSourceSubCategory: data['Lead_Source_Sub_Category__c'],
    formDescription: data['formDescription'],
    product: data['Product__c'],
    country: data['country']
  })
}

const submitForm = async (props, data) => {
  // Don't submit these to marketo
  delete data.id
  delete data.acceptTermsAndConditions

  if (!data.country) {
    data['country'] = props.country
  }

  data['Data_Source__c'] = getDataSource()
  data['Last_Opt_In_Date__c'] =
    data['Email_Opt_In_One__c'] === true ? new Date().toISOString() : null
  data['Third_Party_Opt_In_Date__c'] = data['Third_Party_Consent__c']
    ? new Date().toISOString()
    : null
  data['leadSource'] = props.marketoLeadSource
  data['Lead_Source_Description__c'] = props.marketoLeadSourceOther
  data['Lead_Source_Sub_Category__c'] = props.marketoLeadSourceSubCategory
  data['formUniqueName'] = props.marketoFormUniqueName
  data['formDescription'] = props.marketoFormDescription
  data['Product__c'] = props.marketoProduct
  data['associatedFile'] = props.marketoAssociatedFile
  data['Truphone_Country__c'] = props.marketoTruphoneCounty

  data['thankYouText'] = props.thankYouText ? props.thankYouText.trim().substr(0, 255) : null
  data['ctaText'] = props.ctaText
  data['First_Touch_Campaign__c'] =
    cookies.get('futm_campaign') && decodeURIComponent(cookies.get('futm_campaign'))
  data['First_Touch_Content__c'] =
    cookies.get('futm_content') && decodeURIComponent(cookies.get('futm_content'))
  data['First_Touch_Medium__c'] =
    cookies.get('futm_medium') && decodeURIComponent(cookies.get('futm_medium'))
  data['First_Touch_Source__c'] =
    cookies.get('futm_source') && decodeURIComponent(cookies.get('futm_source'))
  data['First_Touch_Keyword__c'] =
    cookies.get('futm_term') && decodeURIComponent(cookies.get('futm_term'))
  data['marketoLastTouchCampaign'] =
    cookies.get('futm_campaign') && decodeURIComponent(cookies.get('futm_campaign'))
  data['marketoLastTouchContent'] =
    cookies.get('futm_content') && decodeURIComponent(cookies.get('futm_content'))
  data['marketoLastTouchMedium'] =
    cookies.get('futm_medium') && decodeURIComponent(cookies.get('futm_medium'))
  data['marketoLastTouchSource'] =
    cookies.get('futm_source') && decodeURIComponent(cookies.get('futm_source'))
  data['marketoLastTouchKeyword'] =
    cookies.get('futm_term') && decodeURIComponent(cookies.get('futm_term'))
  data['gclidMarketo'] = cookies.get('gclid') && decodeURIComponent(cookies.get('gclid'))
  data['registeredForWebinar'] = props.webinarRegistrationForm
  data['pageURL'] = document.location.href

  const postData = async () => {
    postPageViewToGoogle(data)

    const response = await fetch(`${process.env.API_ENDPOINT}web-to-lead`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })

    // if (response.ok) {
    //   const json = await response.json()
    //   if (json.Status === 'Created') {
    //     let expiry = new Date()
    //     expiry.setFullYear(expiry.getFullYear() + 1)
    //     cookies.set('MarketoLeadUniqueId', json.LeadUniqueId, {
    //       path: '/',
    //       expires: expiry
    //     })
    //   }
    // }
    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop }
    }

    const formBlock = document.querySelector('#form-block')
    const mainMenu = document.querySelector('#main-menu-id')
    const topStripe = document.querySelector('#top-stripe')
    if (formBlock) {
      const formBlockTop =
        offset(formBlock).top - (mainMenu ? 100 : 0) - (topStripe ? topStripe.clientHeight : 0)
      window.scrollTo(0, formBlockTop)
    }
  }

  if (props.appendFields && typeof props.appendFields === 'function') {
    data = await props.appendFields(data)
  }

  await postData()
}

export { submitForm }
