import React, { useEffect } from 'react'
import { ArrowButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import toast, { Toaster } from 'react-hot-toast'
import { Close } from '@truphone/icons'

function ToastBlock(props) {
  const shouldShowToast = () => {
    const history = window.localStorage.getItem('toast_history')
    if (history) {
      try {
        const toastHistory = JSON.parse(history)
        return toastHistory.indexOf(props.id) === -1
      } catch {
        return true
      }
    }

    return true
  }

  const recordSeen = () => {
    let toastHistory
    const history = window.localStorage.getItem('toast_history')
    if (history) {
      try {
        toastHistory = JSON.parse(history)
      } catch {
        toastHistory = []
      }
    } else {
      toastHistory = []
    }

    if (toastHistory.indexOf(props.id) === -1) {
      toastHistory.push(props.id)
      window.localStorage.setItem('toast_history', JSON.stringify(toastHistory))
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    recordSeen()

    if (props.cta.openTab) {
      toast.dismiss(props.id)
      window.open(props.cta.href)
    } else {
      window.location = props.cta.href
    }
  }

  const handleClose = () => {
    toast.dismiss(props.id)
    recordSeen()
  }

  const ToastContent = (
    <div className="relative">
      <div className="h-44">
        <ImageWrap className="w-full h-full object-contain rounded-t-lg" {...props.image} />
        <Close
          className="absolute top-2 right-2 text-white opacity-100 cursor-pointer"
          opacity={1}
          onClick={handleClose}
        />
      </div>
      <div className="m-2 text-center">
        <div
          className="text-sm p-margin my-4 "
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>
        <ArrowButton className="text-blue-100 justify-center" {...props.cta} onClick={handleClick}>
          {props.cta.text}
        </ArrowButton>
      </div>
    </div>
  )

  useEffect(() => {
    if (shouldShowToast()) {
      setTimeout(
        () =>
          toast.custom(
            (t) => (
              <div
                className={`${
                  t.visible ? 'animate-enter' : 'animate-leave'
                }  bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                style={{ width: '260px' }}
              >
                {ToastContent}
              </div>
            ),
            {
              id: props.id,
              duration: Infinity
            }
          ),
        5000
      )
    }
  }, [])

  return (
    <Toaster
      position="bottom-left"
      containerClassName=""
      containerStyle={{
        margin: 0
      }}
      toastOptions={{
        style: {
          padding: 0,
          margin: 0,
          maxWidth: '260px'
        }
      }}
    />
  )
}

export default ToastBlock
