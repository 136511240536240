import React from 'react'
import { Facebook, Instagram, LinkedIn, Medium, Twitter, Vimeo, YouTube } from '@truphone/icons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function ThankYouMessage(props) {
  return (
    <div className="mt-10 bg-white text-black p-8 lg:rounded-2xl shadow text-center max-w-3xl mx-auto">
      <ImageWrap className="mx-auto inline-flex my-10" {...props.icon} />
      {props.topTitle && <p className="overline-text uppercase mb-4">{props.topTitle}</p>}
      {props.title && (
        <h3 className="text-xl lg:text-3xl-custom mb-4 font-semibold leading-tight">
          {props.title}
        </h3>
      )}
      {props.content && (
        <div
          className="text-base text-grey-700 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      )}
      {props.socialsTitle && (
        <div className="">
          <p className="mb-2 uppercase tracking-widest text-grey-700 mt-14">{props.socialsTitle}</p>
          <div className="pt-2 inline-flex justify-center border-t border-gray-200">
            {/* <a
              href="https://www.facebook.com/truphone"
              title="Facebook"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <Facebook className="w-6 h-6" />
            </a> */}
            {/* <a
              href="https://twitter.com/truphone"
              title="Twitter"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <Twitter className="w-6 h-6" />
            </a> */}
            <a
              href="https://www.linkedin.com/company/truphone"
              title="LinkedIn"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <LinkedIn className="w-6 h-6" />
            </a>
            {/* <a
              href="https://medium.com/@Truphone"
              title="Medium"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <Medium className="w-6 h-6" />
            </a> */}
            <a
              href="https://www.youtube.com/user/truphone"
              title="YouTube"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <YouTube className="w-6 h-6" />
            </a>
            <a
              href="https://vimeo.com/truphone/"
              title="Vimeo"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <Vimeo className="w-6 h-6" />
            </a>
            {/* <a
              href="https://www.instagram.com/mytruphone/"
              title="Instagram"
              target="_blank"
              rel="noreferrer nofollow"
              className="block text-black p-3 hover:bg-grey-400 rounded-full"
            >
              <Instagram className="w-6 h-6" />
            </a> */}
          </div>
        </div>
      )}
    </div>
  )
}
