import React from 'react'
import CardSideImage from './CardSideImage'

export default function CardLayout(props) {
  return (
    <div className="relative py-10 lg:py-16">
      {props.backgroundImage && (
        <img
          className="hidden md:block lg:h-full absolute top-0 left-1/2 -translate-x-1/2"
          src={props.backgroundImage.url}
          alt={props.backgroundImage.alt || ''}
        />
      )}
      <div className="flex flex-wrap relative z-1">
        {props.items && (
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap md:rounded-xl md:overflow-hidden">
              {props.items.map((el, i) => {
                return i === 0 ? (
                  <div
                    key={`cardside-${i}`}
                    className="w-full rounded-xl md:rounded-none shadow-lg lg:shadow-none my-3 md:my-0 overflow-hidden"
                  >
                    <CardSideImage large {...props.items[0]} />
                  </div>
                ) : (
                  <div
                    key={`cardside-${i}`}
                    className="w-full md:w-1/2 rounded-xl md:rounded-none shadow-lg md:shadow-none my-3 md:my-0 overflow-hidden"
                  >
                    <CardSideImage {...el} />
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
