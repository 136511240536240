import React from 'react'
import { HeadingH2 } from '../components/Typography'
import Sidebar from '../components/Sidebar'
import RelatedItem from '../components/RelatedItem'
import Share from '../components/Share'

export default function ArticlePage(props) {
  return (
    <div className="bg-white pt-28 pb-20">
      <div className="container mx-auto px-6 flex justify-center">
        {props.shareTitle && <Share shareTitle={props.shareTitle} />}
        <div className="max-w-3xl">
          <HeadingH2 className={'mb-4 leading-tight'}>{props.title}</HeadingH2>
          <h3 className="text-lg mb-4">{props.subtitle}</h3>
          {props.image && (
            <img className="max-w-full w-full mb-4" src={props.image.src} alt={props.image.alt} />
          )}
          <p className="text-sm opacity-60 mb-8">
            {props.publishDate} {' • '} {props.readTime}
          </p>

          <div
            className="text-lg leading-loose p-margin"
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
          {props.productBanner && (
            <a href={props.productBanner.href} className="mt-8 block w-full">
              <img src={props.productBanner.image} alt="" />
            </a>
          )}
        </div>
        {props.sidebar && (
          <div className="hidden lg:block ml-8 max-w-sm">
            <Sidebar sidebar={props.sidebar} />
          </div>
        )}
      </div>
      <div className="w-full border-b border-grey-200 my-10"></div>
      {props.related && (
        <div className="container mx-auto px-6">
          <div className="lg:ml-28">
            {props.related.map((related, i) => {
              return <RelatedItem key={`related-${i}`} related {...related} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}
