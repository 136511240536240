import React, { useRef, useEffect, useState } from 'react'
import { OutlinedButtonBlack, PrimaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { VideoModal } from '@truphone/modals'

export default function CaseStudy(props) {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])
  return (
    <div
      className={`py-10 lg:py-20 relative`}
      style={{
        backgroundColor: props.backgroundColor && props.backgroundColor.hex,
        color: props.textColor && props.textColor.hex
      }}
    >
      <div
        className={`flex items-center relative flex-col lg:flex-row-reverse lg:justify-start container px-3 md:px-10 lg:px-4 mx-auto`}
      >
        <div
          className={`mb-9 lg:mb-0 pb-8 w-full relative ${
            props.imageBackgroundColor ? '' : 'rounded-2xl overflow-hidden'
          } `}
        >
          {props.image && (
            <div ref={containerRef} className={`relative inline-flex`}>
              {props.imageBackgroundColor && props.imageBackgroundColor.hex && (
                <div
                  className={`${
                    isVisible ? 'translate-y-8 translate-x-8' : ''
                  } transition-transform duration-700 absolute top-0 left-0 w-full h-full rounded`}
                  style={{
                    backgroundColor: props.imageBackgroundColor?.hex,
                    color: props.imageTextColor?.hex
                  }}
                ></div>
              )}

              <ImageWrap
                className={`relative   ${props.imageBgColor ? 'shadow' : ''} w-full object-cover`}
                {...props.image}
              />
              {props.videoUrl && (
                <VideoModal isVideo={true} href={props.videoUrl}>
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <div className="hover:scale-105 transition-transform pulse absolute w-full h-full left-0 top-0 rounded-full border-4"></div>
                    <img className="w-20 h-20" src="/images/icons/icon-play-white.svg" alt="play" />
                  </div>
                </VideoModal>
              )}
            </div>
          )}
        </div>
        <div className={`lg:pr-12 w-full`}>
          {props.quote && props.cta ? (
            <a href={props.cta.href} className="italic font-light">
              <div
                className={`text-2xl font-light hover-blue-line `}
                dangerouslySetInnerHTML={{ __html: props.quote }}
              />
            </a>
          ) : (
            props.quote && (
              <div
                className={`text-2xl text-center font-light`}
                dangerouslySetInnerHTML={{ __html: props.quote }}
              />
            )
          )}
          <div className={`${props.quoteTitle ? 'flex mt-10 items-start' : ''}`}>
            {props.companyLogo && (
              <a href={props.cta?.href || '#'} className="mr-4 block shrink-0">
                <ImageWrap
                  className={`w-full h-full object-contain`}
                  src={props.companyLogo.url}
                  alt={props.title}
                />
              </a>
            )}
            <div>
              {props.quoteTitle && <div className=" text-base mb-1">{props.quoteTitle}</div>}

              {props.quoteAuthor && (
                <div
                  className={`${props.videoUrl ? 'mt-2 text-xs' : 'text-base text-center mt-10'}`}
                >
                  <b>{props.quoteAuthor}</b>
                  {props.quoteAuthorJobTitle ? ', ' + props.quoteAuthorJobTitle : ''}
                </div>
              )}
            </div>
          </div>

          {props.cta && (
            <>
              {props.videoUrl ? (
                <PrimaryButton className={`mt-8 w-full sm:w-auto`} href={props.cta.href}>
                  {props.cta.text}{' '}
                </PrimaryButton>
              ) : (
                <OutlinedButtonBlack href={props.cta.href} className="mt-8 w-full sm:w-auto">
                  {props.cta.text}
                </OutlinedButtonBlack>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
