import React, { useState, useEffect } from 'react'
import Field from './Field'
import { useFormContext } from 'react-hook-form'

function CountryList(props) {
  const [countries, setCountries] = useState([])
  const { register, watch, setValue } = useFormContext()

  const value = watch(props.name)

  useEffect(() => {
    if (props.includeRegion && props.regionMarketoFieldName) {
      setValue(props.regionMarketoFieldName, countries.find((x) => x.text === value)?.region)
    }
  }, [value, props.includeRegion, props.regionMarketoFieldName])

  useEffect(() => {
    //Todo, load this dynamically
    const countries =
      require(`./country-json/countries-regions-${props.countryListLanguage.toLowerCase()}`).map(
        (x) => ({ text: x['Country Name'], value: x['ISO'], region: x['region'] })
      )
    countries.sort((a, b) => a.text?.localeCompare(b.text))
    setCountries(countries)
  }, [props.countryListLanguage])

  useEffect(() => {
    if (props.defaultValue && !value) {
      setValue(props.name, props.defaultValue)
    }
  }, [props.name, props.defaultValue, value, setValue])

  const { type, selectFieldOptions, ...rest } = props
  return (
    <>
      <Field type="Select" selectFieldOptions={countries} {...rest} />
      {props.includeRegion && (
        <input
          type="hidden"
          name={props.regionMarketoFieldName}
          {...register(props.regionMarketoFieldName)}
        />
      )}
    </>
  )
}

export default CountryList
