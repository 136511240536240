import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { LinkWrap } from '@truphone/gatsby-wrappers'

function TermsModal(props) {
  return (
    <div style={{ display: props.open ? 'block' : 'none' }}>
      <div className="fixed z-20 top-0 left-0 w-full h-full">
        <div
          className="fixed w-full h-full -z-1 bg-black bg-opacity-50"
          onClick={() => props.requestClose()}
        ></div>
        <div className="bg-white p-8 shadow-lg rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl">
          <svg
            className="cursor-pointer absolute top-2 right-2 z-1 close-icon w-8 h-8 hover:opacity-80"
            onClick={() => props.requestClose()}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
          </svg>

          <h1 className="uppercase font-bold tracking-wide text-center mb-4">{props.title}</h1>
          <div
            className="text-sm leading-normal"
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </div>
      </div>
    </div>
  )
}

function PrivacyPolicy(props) {
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      {props.legalCombinePrivacyWithTermsAndConditions ? (
        <div className={`relative w-full p-2 ${props.short ? 'md:w-1/2' : ''}`}>
          <div className="flex flex-row items-center">
            <input
              className="text-blue-100 border-gray-400 border focus:border-black focus:border-2 rounded-sm"
              name="Email_Opt_In_One__c"
              type="checkbox"
              {...register('Email_Opt_In_One__c', {
                required: { value: true, message: props.legalCombinePolicyValidationMessage }
              })}
            />
            <label
              className="relative z-0 w-full p-2 inline-flex items-center link-underline"
              htmlFor="Email_Opt_In_One__c"
            >
              <span
                className={`ml-2 text-sm ${
                  errors['Email_Opt_In_One__c'] ? 'text-pink' : 'text-black opacity-60'
                } block`}
              >
                {props.legalCombineTextOne}
                <LinkWrap href={props.legalPrivacyPolicyPage} target="_blank">
                  {props.legalPrivacyPolicyLinkText}
                </LinkWrap>
                {props.legalCombineTextTwo}

                <a className="cursor-pointer" href="#" onClick={() => setTermsModalOpen(true)}>
                  {props.legalTermsAndConditionsLinkText}
                </a>
                {' *'}
              </span>
            </label>
          </div>
          {errors['Email_Opt_In_One__c'] && (
            <span className="ml-2 relative text-xs text-pink">
              {errors['Email_Opt_In_One__c'].message}
            </span>
          )}
          <TermsModal
            open={termsModalOpen}
            requestClose={() => setTermsModalOpen(false)}
            title={props.legalTermsAndConditionsPopupTitle}
            content={props.legalTermsAndConditionsPopupContent}
          />
        </div>
      ) : (
        <>
          {props.legalShowTermsAndConditions && (
            <div className={`relative w-full p-2 ${props.short ? 'md:w-1/2' : ''}`}>
              <div className="flex flex-row items-center">
                <input
                  className="text-blue-100 border-gray-400 border focus:border-black focus:border-2 rounded-sm"
                  type="checkbox"
                  id="acceptTermsAndConditions"
                  name="acceptTermsAndConditions"
                  {...register('acceptTermsAndConditions', {
                    required: {
                      value: true,
                      message: props.legalTermsAndConditionsValidationMessage
                    }
                  })}
                />
                <label
                  className="relative z-0 w-full p-2 inline-flex items-center link-underline"
                  htmlFor="acceptTermsAndConditions"
                >
                  <span
                    className={`ml-2 text-sm ${
                      errors['acceptTermsAndConditions'] ? 'text-pink' : 'text-black opacity-60'
                    } block`}
                  >
                    {props.legalTermsAndConditionsLabel}{' '}
                    <a className="cursor-pointer" onClick={() => setTermsModalOpen(true)}>
                      {props.legalTermsAndConditionsLinkText}
                    </a>
                    {' *'}
                  </span>
                </label>
              </div>
              {errors['acceptTermsAndConditions'] && (
                <span className="ml-2 relative text-xs text-pink">
                  {errors['acceptTermsAndConditions'].message}
                </span>
              )}

              <TermsModal
                open={termsModalOpen}
                requestClose={() => setTermsModalOpen(false)}
                title={props.legalTermsAndConditionsPopupTitle}
                content={props.legalTermsAndConditionsPopupContent}
              />
            </div>
          )}
          {props.legalShowPrivacyPolicy && (
            <div className={`relative w-full p-2 ${props.short ? 'md:w-1/2' : ''}`}>
              <div className="flex flex-row items-center">
                <input
                  className="text-blue-100 border-gray-400 border focus:border-black focus:border-2 rounded-sm"
                  type="checkbox"
                  id="Email_Opt_In_One__c"
                  name="Email_Opt_In_One__c"
                  {...register('Email_Opt_In_One__c', {
                    required: { value: true, message: props.legalPrivacyPolicyValidationMessage }
                  })}
                />
                <label
                  className="relative z-0 w-full p-2 link-underline"
                  htmlFor="Email_Opt_In_One__c"
                >
                  <span
                    className={`ml-2 text-sm ${
                      errors['Email_Opt_In_One__c'] ? 'text-pink' : 'text-black opacity-60'
                    }  block`}
                  >
                    {props.legalPrivacyPolicyLabel}{' '}
                    <LinkWrap href={props.legalPrivacyPolicyPage} target="_blank">
                      {props.legalPrivacyPolicyLinkText}
                    </LinkWrap>
                    {' *'}
                  </span>
                </label>
              </div>
              {errors['Email_Opt_In_One__c'] && (
                <span className="ml-2 relative text-xs text-pink">
                  {errors['Email_Opt_In_One__c'].message}
                </span>
              )}
            </div>
          )}
        </>
      )}

      {props.legalShowThirdPartyConsent && (
        <div className={`relative w-full p-2 ${props.short ? 'md:w-1/2' : ''}`}>
          {/* Not a required field */}
          <div className="flex flex-row items-center">
            <input
              className="text-blue-100 border-gray-400 border focus:border-black focus:border-2 rounded-sm"
              type="checkbox"
              name="Third_Party_Consent__c"
              {...register('Third_Party_Consent__c')}
            />
            <label className="relative z-0 w-full p-2 inline-flex items-center link-underline">
              <span className={`ml-2 text-sm text-black opacity-60 block`}>
                {props.legalThirdPartConsentLabel}{' '}
                <LinkWrap href={props.legalThirdPartyConsentPage} target="_blank" rel="noreferrer">
                  {props.legalThirdPartyConsentLinkText}
                </LinkWrap>
              </span>
            </label>
          </div>
        </div>
      )}
    </>
  )
}

export default PrivacyPolicy
