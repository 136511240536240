import React, { useEffect } from 'react'
import { Fragment, useState, useRef } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { Tick } from '../Icons'
import ClickOutside from '../../utils/ClickOutside'

export default function MultiSelectEditable(props) {
  const [selectedItems, setSelectedItems] = useState([])
  const [open, setOpen] = useState(false)
  const [showCross, setShowCross] = useState(false)
  const [query, setQuery] = useState('')
  const formRef = useRef()

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      setSelectedItems(props.options.filter((x) => props.value.includes(x.value)))
    } else {
      setSelectedItems([])
    }
  }, [props.value])

  const handleSelect = (value) => {
    let selected = [...selectedItems]
    if (Array.isArray(value)) {
      selected = value
    } else {
      const index = selected.find((x) => x === value)
      if (index) {
        selected.splice(index, 1)
      } else {
        selected.push(value)
      }
    }

    props.onChange(selected.map((x) => x.value))

    formRef.current.reset()
    setShowCross(false)
    setQuery('')
  }

  const filteredOptions =
    query === ''
      ? props.options
      : props.options.filter((item) =>
          item.text
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  function isSelected(value) {
    return selectedItems?.find((el) => el.value === value.value) ? true : false
  }

  const TriangleIcon = (props) => (
    <div
      className={`${props.open ? 'rotate-180' : ''} w-0 h-0 pointer-events-none`}
      style={{
        borderStyle: 'solid',
        borderWidth: '8px 5.5px 0 5.5px',
        borderColor: '#000000 transparent transparent transparent'
      }}
    ></div>
  )

  return (
    <ClickOutside onClickOutside={() => setOpen(false)}>
      <form ref={formRef} className="flex items-center justify-center">
        <Combobox
          value={selectedItems}
          onChange={(value) => handleSelect(value)}
          multiple
          onClick={() => setOpen(true)}
        >
          <div className="relative mt-1 w-full">
            <div className="relative border border-grey-200 w-full text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-100 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
              <Combobox.Input
                as="div"
                className="flex flex-wrap w-full border-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900"
                displayValue={(item) => item.text}
                onChange={(event) => {
                  setQuery(event.target.value)
                  setShowCross(true)
                }}
                onFocus={() => setOpen(true)}
              >
                {selectedItems.map((item) => (
                  <span
                    key={item.id}
                    className="mr-1 mb-1 inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-blue-900 text-white"
                  >
                    {item.text}
                    <button
                      onClick={() => handleSelect(item)}
                      type="button"
                      className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-white hover:bg-opacity-10  focus:outline-none "
                    >
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))}
                <div className="relative inline-block flex-grow" style={{ minWidth: 80 }}>
                  <input className="px-2 h-7 w-full" />
                  {showCross && formRef.current && formRef.current.value !== '' && (
                    <span
                      className="cursor-pointer absolute right-1 bg-black flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-opacity-80 focus:outline-none "
                      onClick={() => {
                        formRef.current.reset()
                        setShowCross(false)
                        setQuery('')
                      }}
                      style={{ top: 6 }}
                    >
                      <svg
                        className="h-2 w-2 pointer-events-none"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </span>
                  )}
                </div>
                {selectedItems.length === 0 && !open && (
                  <span className="opacity-50 absolute left-3 top-3 pointer-events-none">
                    {props.placeholder}
                  </span>
                )}
              </Combobox.Input>

              <div
                className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3"
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <TriangleIcon open={open} />
              </div>
            </div>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
                afterLeave={() => {
                  setQuery('')
                  setOpen(false)
                }}
              >
                <Combobox.Options
                  static={true}
                  className="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {filteredOptions.length === 0 && open ? (
                    <div className="cursor-default select-none relative py-2 px-4 text-gray-700"></div>
                  ) : (
                    filteredOptions.map((item) => {
                      const selected = isSelected(item)
                      return (
                        <Combobox.Option
                          key={item.id}
                          className={({ active }) =>
                            `cursor-default select-none relative py-2 pl-10 pr-4 ${
                              active ? 'bg-grey-100' : 'text-black'
                            }`
                          }
                          value={item}
                        >
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {item.text}
                          </span>
                          <span
                            className={`${
                              selected ? 'bg-blue-100' : ''
                            } w-5 h-5 absolute inset-y-0 top-2 left-2 border border-grey-400 rounded flex justify-center items-center`}
                          >
                            {selected ? (
                              <Tick className="w-3 h-3 text-white" aria-hidden="true" />
                            ) : null}
                          </span>
                        </Combobox.Option>
                      )
                    })
                  )}
                </Combobox.Options>
              </Transition>
            )}
          </div>
        </Combobox>
      </form>
    </ClickOutside>
  )
}
