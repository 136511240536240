import React from 'react'
import '../style.css'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function NewsCard(props) {
  return (
    <div
      className={`${
        props.fullHeight ? 'h-full' : ''
      } max-w-sm mx-auto lg:mx-0 block no-underline bg-white rounded-sm shadow-sm hover:shadow-md overflow-hidden`}
    >
      {props.image && (
        <div className="h-60 overflow-hidden">
          <ImageWrap className="relative max-w-none h-full" {...props.image} />
        </div>
      )}
      <div className="p-6">
        {props.topTitle && (
          <p className="overline-text font-bold mb-4 uppercase">{props.topTitle}</p>
        )}
        {props.publishedDate && (
          <p className="overline-text font-semibold mb-4 uppercase">
            {props.type} - {props.publishedDate}
          </p>
        )}
        {props.title && <h4 className="text-2xl font-light">{props.title}</h4>}
        <div className="text-base mt-4" dangerouslySetInnerHTML={{ __html: props.content }} />
      </div>
    </div>
  )
}
