import React, { useState, useEffect, useRef } from 'react'
import { uppercaseString } from '@truphone/utils'
import { OutlinedButtonBlack, PrimaryButton, SecondaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { Arrow } from '@truphone/icons'
import { VideoModal } from '@truphone/modals'

export default function ImageTextBlock(props) {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    }
    const refCurrent = containerRef.current
    const observer = new IntersectionObserver(callbackFunction, options)
    if (refCurrent) observer.observe(refCurrent)

    return () => {
      if (refCurrent) observer.unobserve(refCurrent)
    }
  }, [containerRef])

  const linkStyle = props.ctaStyle === 'link'
  const primaryStyle = props.ctaStyle === 'primary'
  const limeGreenStyle = props.ctaStyle === 'lime-green'

  return (
    <div
      ref={containerRef}
      className={`py-10 lg:py-20 relative`}
      style={{
        backgroundColor: props.backgroundColor && props.backgroundColor.hex,
        color: props.textColor && props.textColor.hex
      }}
    >
      {props.backgroundImage && (
        <img
          className="hidden lg:block absolute object-cover w-full h-full top-0 left-0"
          src={props.backgroundImage.url}
          alt={props.backgroundImage.alt}
        />
      )}

      <div
        className={`flex relative ${props.imageRight ? 'lg:flex-row-reverse' : 'lg:flex-row'} 
        ${
          !props.mobileImagePosition || props.mobileImagePosition === 'default'
            ? props.imageRight
              ? 'flex-col-reverse'
              : 'flex-col'
            : props.mobileImagePosition === 'top'
            ? 'flex-col'
            : 'flex-col-reverse'
        } container px-7 md:px-10 lg:px-4 items-center ${
          props.paddingTopBottom ? 'py-12' : ''
        } mx-auto`}
      >
        <div
          className={`${
            props.imageRight || props.mobileImagePosition === 'bottom'
              ? 'mt-9 lg:mt-0'
              : 'mb-9 lg:mb-0'
          } ${props.backgroundImage ? 'lg:w-1/2 ' : ''} w-full relative ${
            props.large || props.backgroundImage
              ? ''
              : props.imageRight
              ? 'max-w-[484px] lg:ml-12'
              : 'lg:mr-12 max-w-[484px]'
          } ${props.imageBackgroundColor ? 'pb-12 lg:pb-0' : ''} ${
            props.large
              ? 'flex flex-col items-center'
              : props.imageBackgroundColor
              ? ''
              : 'rounded-2xl overflow-hidden'
          }`}
        >
          {props.image && (
            <div className={`relative inline-flex w-full`}>
              {props.imageBackgroundColor && props.imageBackgroundColor.hex && (
                <div
                  className={`${
                    isVisible ? 'translate-y-10 translate-x-6' : ''
                  } transition-transform duration-700 absolute top-0 left-0 w-full h-full rounded`}
                  style={{
                    backgroundColor: props.imageBackgroundColor?.hex,
                    color: props.imageTextColor?.hex
                  }}
                >
                  <div className="flex items-center h-8 text-xs lg:text-sm tracking-widest absolute bottom-1 left-2 lg:left-auto lg:right-5">
                    {props.imageText}
                  </div>
                </div>
              )}

              <ImageWrap
                className={`relative ${props.imageBgColor ? 'shadow' : ''} 
                               ${
                                 props.imageObjectFit === 'cover'
                                   ? 'object-cover mx-auto'
                                   : props.imageObjectFit === 'contain'
                                   ? 'object-contain mx-auto'
                                   : props.imageObjectFit === 'none'
                                   ? 'object-none'
                                   : ''
                               }`}
                objectFit={props.imageObjectFit}
                {...props.image}
              />
              {props.videoUrl && (
                <VideoModal isVideo={true} href={props.videoUrl}>
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <div className="border-blue-900 pulse absolute w-full h-full left-0 top-0 rounded-full border-4"></div>
                    <ImageWrap
                      className="w-20 h-20"
                      src="/images/icons/icon-play-dark.svg"
                      alt=""
                    />
                  </div>
                </VideoModal>
              )}
            </div>
          )}
        </div>
        <div
          className={`${props.imageRight ? 'lg:pr-12' : 'lg:pl-12'} ${
            props.backgroundImage ? 'lg:w-1/2' : ''
          } ${props.large ? 'w-full' : ''} ${
            props.large || props.backgroundImage ? '' : 'max-w-xl'
          } `}
        >
          {props.topTitle && (
            <p
              className={`overline-text mb-4 ${props.oneText ? 'text-white' : ''}`}
              style={{
                color: props.textColor && props.textColor.hex
              }}
            >
              {uppercaseString(props.topTitle)}
            </p>
          )}
          {props.oneText ? (
            <h2
              className={`h3-light mb-6 text-white`}
              style={{
                color: props.textColor && props.textColor.hex
              }}
            >
              <span className="one-text-wrap inline-block heron uppercase text-green-100 relative">
                {props.oneText}
                <img
                  className={`one-text block absolute max-w-none`}
                  src="/images/blocks/brush.svg"
                  alt=""
                />
              </span>
              {props.title}
            </h2>
          ) : (
            props.title && <h3 className="h4-normal mb-6">{props.title}</h3>
          )}
          {props.content && (
            <div
              className={`text-base ${
                props.oneText
                  ? 'text-white list-tick list-tick-green'
                  : !props.listStyle || props.listStyle === 'green-ticks'
                  ? 'list-tick-green'
                  : !props.listStyle || props.listStyle === 'square'
                  ? 'list-square list-item-spacing'
                  : 'list-item-spacing'
              } p-margin`}
              dangerouslySetInnerHTML={{ __html: props.content }}
              style={{
                color: props.textColor && props.textColor.hex
              }}
            />
          )}
          {props.quote && props.cta && props.videoUrl ? (
            <a href={props.cta.href} className="italic font-light">
              <div
                className={`text-2xl font-light hover-blue-line `}
                dangerouslySetInnerHTML={{ __html: props.quote }}
              />
            </a>
          ) : (
            props.quote && (
              <div
                className={`text-2xl text-center font-light`}
                dangerouslySetInnerHTML={{ __html: props.quote }}
              />
            )
          )}

          {props.quoteAuthor && (
            <div className={`${props.videoUrl ? 'mt-2 text-xs' : 'text-base text-center mt-10'}`}>
              <b>{props.quoteAuthor}</b>
            </div>
          )}

          {props.footer && (
            <div
              className="text-sm mt-6 list-square list-item-spacing"
              dangerouslySetInnerHTML={{ __html: props.footer }}
              style={{
                color: props.footerTextColor && props.footerTextColor.hex
              }}
            />
          )}

          {props.ctas && props.ctas.length > 0 && (
            <div className="flex flex-col md:flex-row items-center">
              {props.ctas.map((cta, i) => {
                const isVideo = cta.href?.match(/youtu\.be|vimeo\.com/)
                const button = (
                  <>
                    {linkStyle ? (
                      <a
                        href={cta.href}
                        target={cta.openTab ? '_blank' : ''}
                        className={`mt-8 pr-4 ${
                          props.backgroundColor && props.backgroundColor.hex === '#070158'
                            ? 'text-blue-100'
                            : ''
                        } text-lg font-bold flex items-center hover-arrow`}
                        style={{
                          color:
                            props.backgroundColor && props.backgroundColor.hex === '#070158'
                              ? ''
                              : props.textColor && props.textColor.hex
                        }}
                      >
                        {cta.text}
                        <Arrow
                          className={`ml-3 w-4 h-4 -rotate-90 icon-arrow transition-transform`}
                        />
                      </a>
                    ) : primaryStyle ? (
                      <PrimaryButton
                        className={`mt-8 pr-4`}
                        href={cta.href}
                        target={cta.openTab ? '_blank' : ''}
                      >
                        {cta.text}{' '}
                      </PrimaryButton>
                    ) : limeGreenStyle ? (
                      <SecondaryButton
                        className={`mt-8 pr-4`}
                        href={cta.href}
                        target={cta.openTab ? '_blank' : ''}
                      >
                        {cta.text}{' '}
                      </SecondaryButton>
                    ) : (
                      <OutlinedButtonBlack
                        href={cta.href}
                        className="mt-8 pr-4"
                        target={cta.openTab ? '_blank' : ''}
                      >
                        {cta.text}
                      </OutlinedButtonBlack>
                    )}
                  </>
                )

                if (cta.image) {
                  return (
                    <a
                      key={`button-${i}`}
                      className="mt-8 pr-4"
                      href={cta.href}
                      title={cta.title}
                      target={cta.openTab ? '_blank' : null}
                      rel={cta.openTab ? 'noreferrer' : null}
                    >
                      <ImageWrap {...cta.image} />
                    </a>
                  )
                } else if (isVideo) {
                  return (
                    <VideoModal isVideo={true} href={cta.href}>
                      {button}
                    </VideoModal>
                  )
                } else {
                  return button
                }
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
