import React from 'react'

export const Search = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M11.071 21.143c5.563 0 10.072-4.51 10.072-10.072C21.143 5.51 16.633 1 11.07 1 5.51 1 1 5.51 1 11.071c0 5.563 4.51 10.072 10.071 10.072z"
          fillRule="nonzero"
        />
        <path d="m22.429 22.429-4.23-4.23" />
      </g>
    </svg>
  )
}
export const Close = (props) => {
  const { opacity, ...rest } = props
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.949 15.536a.999.999 0 1 1-1.414 1.414L12 13.414 8.465 16.95a1 1 0 1 1-1.415-1.414L10.586 12 7.05 8.465a.999.999 0 1 1 1.414-1.414L12 10.586l3.535-3.535a.999.999 0 1 1 1.414 1.414L13.414 12l3.535 3.536zM12 1C5.925 1 1 5.925 1 12c0 6.076 4.925 11 11 11s11-4.924 11-11c0-6.075-4.925-11-11-11z"
        fill="currentColor"
        fillRule="evenodd"
        fillOpacity={opacity || '0.6'}
        opacity={opacity || '0.6'}
      />
    </svg>
  )
}
export const Tick = (props) => {
  return (
    <svg {...props} width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m3 9.5 3.49 3.6c1.644-4.72 3.011-6.79 6.11-9.6"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export const Arrow = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m14 8-6 6-6-6M8 14V2" />
      </g>
    </svg>
  )
}
export const ArrowLinkOutSide = (props) => {
  return (
    <svg {...props} width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.504 2.22H5.046a.496.496 0 0 0-.496.496v.496c0 .274.222.497.496.497H7.86c.221 0 .332.267.175.423l-6.39 6.39a.497.497 0 0 0 0 .702l.351.35a.497.497 0 0 0 .702 0l6.39-6.39a.248.248 0 0 1 .423.176v2.814c0 .274.222.496.497.496h.496A.496.496 0 0 0 11 8.174V2.716a.496.496 0 0 0-.496-.496"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
export const ArrowLinkOutSideBox = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1.334v2H3.593a.26.26 0 0 0-.26.258v8.816c0 .142.117.259.26.259h8.814a.26.26 0 0 0 .26-.26V10h2v2.408a2.262 2.262 0 0 1-2.26 2.259H3.593a2.262 2.262 0 0 1-2.26-2.26V3.593a2.262 2.262 0 0 1 2.26-2.258H6zm8.333 0c.184 0 .334.149.334.333v6.29c0 .297-.36.446-.57.236L12.045 6.14a.334.334 0 0 0-.471 0L8.617 9.097a.332.332 0 0 1-.472 0L6.903 7.855a.332.332 0 0 1 0-.472l2.956-2.956a.334.334 0 0 0 0-.472L7.807 1.903a.333.333 0 0 1 .236-.57z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
export const ArrowSimple = (props) => {
  return (
    <svg {...props} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.818a.83.83 0 0 1 .231-.578.77.77 0 0 1 1.117 0L6 7.025 9.653 3.24a.769.769 0 0 1 1.116 0 .839.839 0 0 1 0 1.157L6.559 8.76a.77.77 0 0 1-1.117 0L1.23 4.397A.832.832 0 0 1 1 3.818"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
export const Link = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <path
          id="alinka"
          d="M20.415 3.404c.78.781.78 2.053.001 2.834L15.46 11.2c-.381.38-.884.588-1.418.588a1.99 1.99 0 0 1-1.414-.586l-.002-.002 1.416-1.417h.002L19 4.822l-2.832-2.835-4.958 4.96-1.417 1.418a2.01 2.01 0 0 1 0-2.834L14.748.569c.76-.758 2.074-.758 2.832-.002l2.834 2.837zM9.792 14.034l1.416-1.417c.78.782.78 2.054.001 2.834l-4.958 4.962c-.38.38-.883.587-1.416.587a1.99 1.99 0 0 1-1.415-.585L.585 17.578a2.01 2.01 0 0 1 0-2.834l4.957-4.961c.76-.76 2.074-.76 2.832-.002l.002.002L6.959 11.2h-.002l-4.955 4.96 2.834 2.835 4.956-4.96h-.001.001zm-2.124-.708a1.002 1.002 0 0 1 0-1.418l.85-.85 3.398-3.402a.999.999 0 0 1 1.417 0 1.003 1.003 0 0 1 0 1.418l-4.25 4.252a.999.999 0 0 1-1.415 0z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="blinkb" fill="#fff">
          <use href="#alinka"></use>
        </mask>
        <use fill="#00313F" href="#alinka"></use>
        <g mask="url(#blinkb)">
          <path d="M-2-2h24v24H-2z"></path>
        </g>
      </g>
    </svg>
  )
}

export const Medium = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <path
            d="M12.283 13.2c-.056-.103-.082-.145-.102-.188-1.023-2.247-2.05-4.492-3.065-6.742-.102-.227-.21-.333-.48-.33-1.218.018-2.436.005-3.654.012-.107 0-.213.062-.32.094.045.108.067.233.137.32.388.485.804.948 1.176 1.444.134.18.245.429.247.647.02 2.168.02 4.335.006 6.502-.002.2-.098.43-.221.59-.445.58-.924 1.132-1.38 1.702-.084.106-.12.25-.2.418h4.517c-.089-.18-.132-.326-.22-.436-.45-.56-.925-1.1-1.358-1.672-.136-.18-.234-.437-.237-.66-.018-1.74-.009-3.482-.007-5.223 0-.087.012-.174.024-.356.09.188.138.283.183.38l3.513 7.66c.08.175.139.416.396.315.138-.054.283-.202.339-.341 1.022-2.564 2.03-5.133 3.044-7.7.026-.066.064-.126.14-.272 0 1.818-.07 3.538.024 5.25.061 1.125-.172 2.018-1.137 2.655-.04.026-.085.06-.103.1-.04.093-.065.191-.105.315h5.888c-.088-.159-.127-.281-.208-.365-.312-.324-.648-.626-.953-.957-.097-.104-.176-.27-.177-.409-.01-2.74-.01-5.48.001-8.219.001-.15.082-.331.185-.442.316-.337.665-.642.991-.97.069-.069.092-.181.137-.274-.094-.033-.188-.095-.283-.096-1.181-.006-2.363.001-3.544-.009-.219-.002-.32.07-.4.273-.523 1.336-1.059 2.666-1.593 3.998l-1.2 2.986zM1 23h22V1H1v22z"
            transform="translate(-297 -3052) translate(119 2352) translate(0 640) translate(1 48) translate(165) translate(12 12)"
          />
        </g>
      </g>
    </svg>
  )
}
export const Vimeo = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <g fill="#45BBFF">
        <path
          d="M22.99 6.402c-.099 2.142-1.594 5.073-4.487 8.795-2.992 3.887-5.522 5.83-7.592 5.83-1.283 0-2.368-1.183-3.255-3.55l-1.775-6.511c-.659-2.367-1.364-3.551-2.12-3.551-.165 0-.74.346-1.727 1.036L1 7.118c1.086-.954 2.156-1.908 3.21-2.863 1.448-1.25 2.536-1.909 3.26-1.975 1.712-.165 2.766 1.005 3.161 3.51.428 2.703.724 4.384.89 5.042.494 2.242 1.037 3.362 1.63 3.362.46 0 1.152-.727 2.074-2.183.922-1.454 1.415-2.56 1.481-3.321.132-1.255-.361-1.884-1.48-1.884-.527 0-1.07.121-1.629.36 1.082-3.54 3.147-5.26 6.196-5.162 2.26.067 3.327 1.532 3.196 4.398"
          transform="translate(-407 -3052) translate(119 2352) translate(0 640) translate(1 48) translate(275) translate(12 12)"
        />
      </g>
    </svg>
  )
}
export const Twitter = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd" style={{ transform: 'translate(0px, 3px)' }}>
        <g fill="#00ACED">
          <path
            d="M21.66 2.086c-.797.354-1.653.593-2.552.7.917-.55 1.622-1.422 1.954-2.46-.859.51-1.81.88-2.822 1.079C17.43.54 16.274 0 14.996 0c-2.454 0-4.444 1.992-4.444 4.449 0 .349.04.688.116 1.014C6.974 5.277 3.7 3.506 1.508.814c-.382.657-.602 1.422-.602 2.237 0 1.543.785 2.905 1.977 3.703-.728-.023-1.413-.223-2.013-.556v.056c0 2.155 1.532 3.953 3.565 4.362-.373.102-.766.156-1.171.156-.286 0-.565-.028-.836-.08.565 1.768 2.207 3.054 4.151 3.09-1.52 1.194-3.437 1.905-5.519 1.905-.359 0-.712-.021-1.06-.063 1.967 1.263 4.302 2 6.812 2 8.174 0 12.644-6.78 12.644-12.66 0-.192-.005-.384-.013-.575.868-.627 1.621-1.41 2.217-2.303z"
            transform="translate(-243 -3045) translate(119 2342) translate(0 640) translate(1 48) translate(110) translate(12 12) translate(1 3)"
          />
        </g>
      </g>
    </svg>
  )
}
export const Facebook = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <g fill="#0084FF">
        <path
          d="M21.786 1H2.214C1.544 1 1 1.544 1 2.216V21.81c0 .672.544 1.216 1.214 1.216h10.537v-8.53H9.884v-3.324h2.867V8.721c0-2.845 1.735-4.394 4.27-4.394 1.215 0 2.258.09 2.562.13v2.974h-1.758c-1.378 0-1.645.656-1.645 1.619v2.122h3.287l-.428 3.324h-2.86v8.53h5.607c.67 0 1.214-.544 1.214-1.216V2.216C23 1.544 22.456 1 21.786 1"
          transform="translate(-187 -839) translate(119 139) translate(0 640) translate(1 48) translate(55) translate(12 12)"
        />
      </g>
    </svg>
  )
}
export const YouTube = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <g fill="red">
        <path
          d="M9.729 14.59L9.728 8.41l5.944 3.101-5.944 3.08zM22.78 7.338s-.215-1.516-.875-2.184c-.836-.876-1.774-.88-2.204-.931C16.623 4 12.005 4 12.005 4h-.01S7.377 4 4.3 4.223c-.43.05-1.368.055-2.205.931-.66.668-.874 2.184-.874 2.184S1 9.118 1 10.898v1.67c0 1.78.22 3.56.22 3.56s.215 1.516.874 2.184c.837.876 1.937.849 2.426.94 1.76.17 7.48.222 7.48.222s4.623-.007 7.701-.23c.43-.052 1.368-.056 2.204-.932.66-.668.875-2.184.875-2.184s.22-1.78.22-3.56v-1.67c0-1.78-.22-3.56-.22-3.56z"
          transform="translate(-352 -839) translate(119 139) translate(0 640) translate(1 48) translate(220) translate(12 12)"
        />
      </g>
    </svg>
  )
}
export const LinkedIn = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#0077B5">
          <path
            d="M19.748 19.746h-3.263v-5.105c0-1.218-.021-2.784-1.696-2.784-1.698 0-1.957 1.327-1.957 2.697v5.192h-3.26V9.248h3.129v1.435h.045c.435-.826 1.5-1.696 3.087-1.696 3.304 0 3.915 2.174 3.915 5.002v5.757zM5.894 7.813C4.846 7.813 4 6.966 4 5.921s.845-1.892 1.893-1.892c1.044 0 1.89.847 1.89 1.892s-.846 1.892-1.89 1.892zM4.26 19.746h3.266V9.248H4.26v10.498zM21.373 1H2.623C1.727 1 1 1.71 1 2.586v18.826C1 22.29 1.727 23 2.623 23h18.75C22.27 23 23 22.289 23 21.412V2.586C23 1.71 22.27 1 21.373 1z"
            transform="translate(-132 -839) translate(119 139) translate(0 640) translate(1 48) translate(12 12)"
          />
        </g>
      </g>
    </svg>
  )
}

export const Instagram = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <linearGradient id="gvedvzs3na" x1="66.814%" x2="98.343%" y1="-9.869%" y2="110.803%">
            <stop offset="0%" stopColor="#4E60D3" />
            <stop offset="14.276%" stopColor="#913BAF" />
            <stop offset="76.146%" stopColor="#D52D88" />
            <stop offset="100%" stopColor="#F26D4F" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g fill="url(#gvedvzs3na)" transform="translate(-462 -3042)">
            <path
              d="M16.673 1.029c3.478 0 6.298 2.82 6.298 6.298v9.23c0 3.478-2.82 6.298-6.298 6.298H7.327c-3.478 0-6.298-2.82-6.298-6.298v-9.23c0-3.478 2.82-6.298 6.298-6.298h9.346zm0 2.032H7.327c-2.356 0-4.266 1.91-4.266 4.266v9.23c0 2.356 1.91 4.266 4.266 4.266h9.346c2.356 0 4.266-1.91 4.266-4.266v-9.23c0-2.356-1.91-4.266-4.266-4.266zm-4.644 3.192c3.19 0 5.776 2.586 5.776 5.776s-2.586 5.776-5.776 5.776-5.776-2.586-5.776-5.776S8.84 6.253 12.03 6.253zm0 2.032c-2.068 0-3.744 1.676-3.744 3.744 0 2.068 1.676 3.744 3.744 3.744 2.068 0 3.744-1.676 3.744-3.744 0-2.068-1.676-3.744-3.744-3.744zm5.834-3.57c.77 0 1.393.624 1.393 1.393 0 .77-.624 1.393-1.393 1.393-.77 0-1.393-.623-1.393-1.393s.623-1.393 1.393-1.393z"
              transform="translate(119 2342) translate(0 640) translate(1 48) translate(330) translate(12 12)"
            />
          </g>
        </g>
      </svg>
    </svg>
  )
}
