import React from 'react'
import { Arrow, Twitter, Facebook, LinkedIn, Medium } from './Icons'
import { HeadingH3Light } from './Typography'
import './sidebar.css'

const ClippPathImg = () => {
  return (
    <svg width="0" height="0">
      <defs>
        <clipPath id="oval">
          <path
            id="6xrpauhlba"
            d="M61.295 14.284l-3.636-3.639c-13.914-13.932-30.317-14.452-44.752 0l-2.264 2.267c-14.052 14.07-14.33 30.461 0 44.809l3.634 3.639c13.7 13.716 30.12 14.65 44.752 0l2.264-2.267c14.187-14.204 14.364-30.427.002-44.809z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function Sidebar(props) {
  return (
    <>
      {props.sidebar &&
        props.sidebar.length > 0 &&
        props.sidebar.map((content, i) => {
          let contentElement = <></>

          if (content.type === 'LogoImage') {
            contentElement = (
              <div
                key={`sidebar-content-${i}`}
                className="w-44 h-44 flex items-center rounded p-5 shadow mb-8"
              >
                <img src={content.image} alt={content.alt} />
              </div>
            )
          }
          if (content.type === 'SideBanner') {
            contentElement = (
              <div
                key={`sidebar-content-${i}`}
                className="relative overflow-hidden text-white rounded p-8 shadow mb-8 "
              >
                <div
                  className="absolute w-full h-full top-0 left-0 bg-cover duration-500 ease-out transition-all hover:scale-125 "
                  style={{ backgroundImage: `url(${content.bgimage}` }}
                ></div>
                <div className="relative z-1">
                  <h3 className="heron text-3xl tracking-wider uppercase mb-4">{content.title}</h3>
                  <p className="text-base" dangerouslySetInnerHTML={{ __html: content.content }} />
                  {content.cta && (
                    <a className="mt-8 flex items-center" href={content.cta.href}>
                      {content.cta.text}
                      <Arrow className="ml-4 w-4 -rotate-90"></Arrow>
                    </a>
                  )}
                </div>
              </div>
            )
          }
          if (content.type === 'Tweet') {
            contentElement = (
              <div key={`sidebar-content-${i}`} className="my-20 hover-tweet text-center">
                <HeadingH3Light className="text-left">{content.text}</HeadingH3Light>
                <div className="tweet relative inline-flex shadow p-2 rounded mx-auto mt-8">
                  <div className="bg-white absolute w-full h-full top-0 left-0 rounded"></div>
                  <Twitter className="relative z-1 w-6" />
                </div>
              </div>
            )
          }
          if (content.type === 'RecentNews') {
            contentElement = (
              <div key={`sidebar-content-${i}`} className="relative">
                <div className="flex justify-between border-b border-gray-200 pb-2">
                  <h4 className="text-base font-bold uppercase tracking-widest opacity-60">
                    {content.title}
                  </h4>
                  {content.seeAll && (
                    <a
                      className="flex font-bold items-center text-blue-100 hover:text-blue-100 text-lg"
                      href={content.seeAll.href}
                    >
                      {content.seeAll.text}
                      <Arrow className="ml-4 w-4 -rotate-90"></Arrow>
                    </a>
                  )}
                </div>
                {content.items &&
                  content.items.map((item, i) => {
                    return (
                      <div className="my-8" key={`sidebar-item-${i}`}>
                        <p className="text-xs font-light mb-2">{item.date}</p>
                        <a href={item.href} target={item.openTab ? '_blank' : ''}>
                          <h4 className="hover-blue-line text-base font-bold">{item.title}</h4>
                        </a>
                      </div>
                    )
                  })}
              </div>
            )
          }
          if (content.type === 'PressContact') {
            contentElement = (
              <div
                key={`sidebar-content-${i}`}
                className="relative border-t border-b border-gray-200 pt-10 pb-5"
              >
                <ClippPathImg />
                <div className="flex">
                  {content.image && (
                    <img
                      className="w-20 overflow-hidden"
                      src={content.image.src}
                      alt={content.image.alt}
                      style={{ clipPath: 'url(#oval)' }}
                    />
                  )}
                  <div className={`${content.image ? 'ml-4' : ''}`}>
                    <h4 className="text-base font-bold uppercase tracking-widest opacity-60 mb-2">
                      {content.name}
                    </h4>
                    {content.jobTitle && <p className="italic text-xs">{content.jobTitle}</p>}
                    {content.company && <p className="italics text-xs">{content.company}</p>}
                  </div>
                </div>
                {content.content && (
                  <p
                    className="mt-4 text-sm"
                    dangerouslySetInnerHTML={{ __html: content.content }}
                  />
                )}
                {content.social && (
                  <div className="flex justify-center mt-6">
                    {content.social.linkedIn && (
                      <a
                        className="m-3 block"
                        href={`https://www.linkedin.com/${content.social.linkedIn}`}
                      >
                        <LinkedIn className="w-6" />
                      </a>
                    )}
                    {content.social.twitter && (
                      <a
                        className="m-3 block"
                        href={`https://twitter.com/${content.social.twitter}`}
                      >
                        <Twitter className="w-6" />
                      </a>
                    )}
                    {content.social.facebook && (
                      <a
                        className="m-3 block"
                        href={`https://www.facebook.com/${content.social.facebook}`}
                      >
                        <Facebook className="w-6" />
                      </a>
                    )}
                    {content.social.medium && (
                      <a className="m-3 block" href={content.social.medium}>
                        <Medium className="w-6" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            )
          }
          return contentElement
        })}
    </>
  )
}
