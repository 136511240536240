import '../style.css'
import { uppercaseString } from '@truphone/utils'
import React, { useState } from 'react'
import Links from './Links'
import {
  SecondaryButton,
  OutlinedButtonBlack,
  OutlinedButtonWhite,
  ArrowButton,
  PrimaryButton
} from '@truphone/buttons'

import { ImageWrap } from '@truphone/gatsby-wrappers'
import { VideoModal } from '@truphone/modals'
import loadable from '@loadable/component'

const Select = loadable(() => import('./form/Select'))
const SnackBox = loadable(() => import('./SnackBox'))

function HeroForm(props) {
  const [values, setValues] = useState({})

  const updateValues = (name, value) => {
    setValues((prevValue) => {
      const newValues = { ...prevValue }
      newValues[name] = value
      return newValues
    })
  }

  const submit = (e, href, openTab) => {
    e.preventDefault()

    const url = new URL(href)
    const searchParams = new URLSearchParams()
    Object.getOwnPropertyNames(values).forEach((name) => {
      searchParams.append(name, values[name])
    })

    url.search = searchParams.toString()
    if (openTab) {
      window.open(url.toString(), '_blank')
    } else {
      window.location = url.toString()
    }
  }

  return (
    <div className="mt-10 lg:mt-0 w-full lg:w-1/2 lg:pl-10">
      <div className="w-full mb-40 relative z-1 flex flex-col lg:max-w-lg text-black bg-gray-100 p-6 border-t-4 border-green-100 shadow-md rounded rounded-b-2xl mx-auto lg:mr-0">
        {props.form.title && (
          <h3 className={`lg:text-2xl text-xl font-semibold mb-2`}>{props.form.title}</h3>
        )}
        {props.form.content && (
          <div
            className={`body1-muted mb-4`}
            dangerouslySetInnerHTML={{ __html: props.form.content }}
          />
        )}
        {props.form && props.form.fields && (
          <div className="mb-6">
            {props.form.fields.map((field, i) => {
              return (
                <div key={`field-${i}`} className="mt-4">
                  <p className="text-sm mt-2 mb-1">{field.label}</p>
                  {field.fieldType === 'Text' && (
                    <input
                      className="w-full rounded-lg border border-gray-200"
                      type={field.type}
                      name={field.name}
                      onChange={(e) => updateValues(field.name, e.target.value)}
                    />
                  )}
                  {field.fieldType === 'Select' && (
                    <Select
                      className={'bg-white border border-grey-200 rounded-lg'}
                      options={field.items}
                      name={field.name}
                      value={values[field.name]}
                      onChange={(e) => updateValues(field.name, e)}
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}

        {props.form &&
          props.form.ctas &&
          props.form.ctas.map((cta, i) => {
            return cta.outlined ? (
              <OutlinedButtonBlack
                key={`cta-${i}`}
                className={`${props.maxWidth ? 'w-full md:w-auto' : 'w-full'} mt-4`}
                href={cta.href}
                onClick={(e) => submit(e, cta.href, cta.openTab)}
                target={cta.openTab ? '_blank' : ''}
              >
                {cta.text}
              </OutlinedButtonBlack>
            ) : (
              <SecondaryButton
                key={`cta-${i}`}
                className="mt-4 w-full"
                href={cta.href}
                onClick={(e) => submit(e, cta.href, cta.openTab)}
                target={cta.openTab ? '_blank' : ''}
              >
                {cta.text}
              </SecondaryButton>
            )
          })}
      </div>
    </div>
  )
}

function GatedFullWidthHero(props) {
  return (
    <div className="w-full pt-28 px-6 mx-auto container">
      <h1
        className="heron leading-none text-5xl md:text-6xl lg:whitespace-pre-line mt-4 mb-6"
        dangerouslySetInnerHTML={{ __html: uppercaseString(props.title) }}
      />
      <p className="overline-text text-grey-700">{uppercaseString(props.topTitle)}</p>
      <p className="mt-4 h5-light lg:whitespace-pre-line">{props.subtitle}</p>
    </div>
  )
}

function GatedWithFormHero(props) {
  return (
    <div className="absolute w-full overflow-hidden">
      {props.backgroundImage && (
        <div className="hidden lg:block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
          <ImageWrap
            className="block h-full w-full object-cover"
            {...props.backgroundImage}
            loading="eager"
          />
        </div>
      )}

      {props.backgroundImageMobile && (
        <div className="lg:hidden w-full h-full absolute left-0 top-0 bg-cover bg-center">
          <ImageWrap
            className="block h-full w-full object-cover"
            {...props.backgroundImageMobile}
            loading="eager"
          />
        </div>
      )}

      <div className="mx-auto flex flex-wrap pt-20 container pb-20">
        <div className="w-full lg:w-1/2 p-6 pb-0 shrink-0">
          {props.topTitle && <p className="overline-text uppercase mb-4">{props.topTitle}</p>}
          {props.title && <h1 className="heron text-3xl lg:text-6xl uppercase">{props.title}</h1>}
          {props.subtitle && (
            <p className="text-base lg:text-2xl font-light mt-4">{props.subtitle}</p>
          )}
        </div>
      </div>
    </div>
  )
}

function HeroTitle(props) {
  let titleHtml = uppercaseString(props.title)
  if (props.highlightText) {
    const regx = new RegExp(props.highlightText, 'i')
    titleHtml = titleHtml.replace(regx, '<span class="text-green-100">$&</span>')
  }

  return (
    <h1
      className={`heron leading-none text-5xl md:text-6xl lg:whitespace-pre-line ${
        props.titleLarge ? 'lg:text-8xl' : props.alignLeftLarge ? 'lg:text-7xl text-7xl-resp' : ''
      } mt-4 mb-6`}
      dangerouslySetInnerHTML={{ __html: titleHtml }}
    />
  )
}
export default function Hero(props) {
  if (props.layout === 'GatedFullWidth') {
    return <GatedFullWidthHero {...props} />
  } else if (props.layout === 'GatedWithForm') {
    return <GatedWithFormHero {...props} />
  }

  const outerDivBackgroundStyle = {}
  if (props.gradient) {
    outerDivBackgroundStyle.backgroundImage = props.gradient
      ? props.gradient
      : 'radial-gradient(circle at 5% 5%, #4156cb, #27c3b6 88%, #adf22e 107%)'
  } else if (props.backgroundColor) {
    outerDivBackgroundStyle.backgroundColor = props.backgroundColor.hex
  }
  if (props.style === 'technology') {
    outerDivBackgroundStyle.backgroundImage =
      'radial-gradient(circle at 5% 5%, rgb(65, 86, 203), rgb(39, 195, 182) 81%, rgb(173, 242, 46) 98%)'
  }

  return (
    <>
      <div
        className={`relative overflow-hidden ${
          props.secondaryMenu ? 'pt-40 lg:pt-48' : 'pt-36 lg:pt-44 '
        } 
      ${
        props.pblarge
          ? 'pb-48'
          : props.floatingText
          ? 'pb-8 lg:pb-20'
          : props.noPaddingBottom
          ? 'pb-0'
          : props.inPageLinks && props.inPageLinks.length > 0 && !props.video
          ? 'pb-80 md:pb-0'
          : props.inPageLinks && props.inPageLinks.length > 0
          ? 'pb-8 md:pb-0'
          : props.form
          ? 'pb-10 lg:pb-24'
          : props.image
          ? 'pb-24'
          : props.smallPaddingBottomMobile
          ? 'pb-10 lg:pb-16'
          : 'pb-80 lg:pb-24'
      } ${props.textStyle === 'dark' ? 'text-black' : 'text-white'} ${
          props.backgroundContain ? 'pt-26' : props.secondaryMenu ? 'pt-44' : ''
        } relative z-0 ${props.backgroundImage ? 'bg-cover' : ''}   
        ${props.heroRight ? 'bg-right' : 'bg-left'}
        ${props.borderBottom ? 'border-b border-gray-300' : ''}`}
        style={outerDivBackgroundStyle}
      >
        {props.snackBox && props.snackBox.text && <SnackBox {...props.snackBox} />}

        {props.backgroundImage && (
          <div className="hidden lg:block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
            <ImageWrap
              className="block w-full h-full object-cover"
              {...props.backgroundImage}
              loading="eager"
            />
          </div>
        )}
        {props.backgroundImageMobile && (
          <div className="lg:hidden w-full h-full absolute left-0 top-0 bg-cover bg-center">
            <ImageWrap
              className="block w-full h-full object-cover"
              {...props.backgroundImageMobile}
              loading="eager"
            />
          </div>
        )}
        <div className={`relative container px-5 md:px-6 mx-auto`}>
          <div className={`${props.floatingText || props.form ? 'flex flex-wrap' : ''}`}>
            <div
              className={`${
                props.alignLeft
                  ? 'lg:max-w-lg'
                  : props.alignLeftLarge
                  ? 'lg:max-w-2xl'
                  : 'text-left lg:text-center mx-auto '
              } ${props.smallContainerWidth ? 'max-w-3xl' : ''}
              ${props.floatingText || props.form ? 'w-full lg:w-1/2' : ''}     
           z-1 relative`}
            >
              <p
                className="overline-text"
                style={{ color: props.topTitleColor ? props.topTitleColor.hex : null }}
              >
                {uppercaseString(props.topTitle)}
              </p>
              <HeroTitle
                title={props.title}
                titleLarge={props.titleLarge}
                alignLeftLarge={props.alignLeftLarge}
                highlightText={props.titleHighlightText}
              />
              {props.floatingText && (
                <p
                  className="lg:hidden heron text-9xl text-green-100 uppercase whitespace-nowrap"
                  style={{ fontSize: 'clamp(18vw, 16vw + 7rem, 22rem)', lineHeight: 0.8 }}
                >
                  {props.floatingText}
                </p>
              )}
              <p
                className={` ${
                  props.subtitleWidth ? 'mx-auto max-w-3xl' : ''
                } mt-4 h5-light lg:whitespace-pre-line`}
              >
                {props.subtitle}
              </p>
            </div>

            {props.floatingText && (
              <div
                className={` ${
                  props.floatingText
                    ? 'flex items-center lg:w-1/2 pl-16 pt-10 whitespace-nowrap'
                    : ''
                }`}
              >
                <p
                  className="hidden lg:block heron text-9xl text-green-100 uppercase"
                  style={{ fontSize: 'clamp(18rem, 20vw + 2rem, 22rem)', lineHeight: 0.8 }}
                >
                  {props.floatingText}
                </p>
              </div>
            )}
            {props.form && <HeroForm {...props} />}
            {props.logo && (
              <div className="hidden lg:block lg:float-right">
                <ImageWrap {...props.logo} loading="eager" className="block" />
              </div>
            )}
          </div>
          <div
            className={`${
              props.alignLeftLarge || props.alignLeft ? '' : 'text-left lg:text-center mx-auto '
            }`}
          >
            {props.ctas && props.ctas.length > 0 && (
              <div
                className={`mt-4 -mx-2 ${
                  props.alignLeft || props.alignLeftLarge
                    ? ''
                    : 'flex flex-col sm:flex-row lg:justify-center'
                }`}
              >
                {props.ctas.map((cta, i) => {
                  const isVideo = cta.href?.match(/youtu\.be|vimeo\.com/)
                  const style = cta.style ? cta.style : i == 0 ? 'secondary' : 'outline'

                  return isVideo ? (
                    <VideoModal isVideo={true} href={cta.href}>
                      <OutlinedButtonWhite
                        key={`cta-video`}
                        className="mx-2 my-2 min-w--250"
                        href={cta.href}
                        target={cta.openTab ? '_blank' : ''}
                      >
                        {cta.text}
                      </OutlinedButtonWhite>
                    </VideoModal>
                  ) : cta.primary ? (
                    <PrimaryButton
                      key={`cta${i}`}
                      className="mx-2 my-2 min-w--250"
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                    >
                      {cta.text}
                    </PrimaryButton>
                  ) : style === 'secondary' ? (
                    <SecondaryButton
                      key={`cta${i}`}
                      className="mx-2 my-2 min-w--250"
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                    >
                      {cta.text}
                    </SecondaryButton>
                  ) : style === 'outline' ? (
                    <OutlinedButtonWhite
                      key={`cta${i}`}
                      className="mx-2 my-2 min-w--250"
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                    >
                      {cta.text}
                    </OutlinedButtonWhite>
                  ) : style === 'text' ? (
                    <ArrowButton
                      key={`cta${i}`}
                      className="mx-2 my-2 min-w--250 text-blue-100 lg:text-xl font-medium"
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                    >
                      {cta.text}
                    </ArrowButton>
                  ) : (
                    <></>
                  )
                })}
              </div>
            )}
          </div>

          {props.video && (
            <div
              className={`relative lg:absolute top-0 right-0 pt-24 pb-20 lg:max-w-xl h-full w-full flex justify-center items-center`}
            >
              {' '}
              <VideoModal isVideo={true} href={props.video}>
                <img className="w-32 h-32" src="/images/icons/icon-play.svg" alt="play" />
              </VideoModal>
            </div>
          )}
          {props.image && (
            <div className="mt-10 flex justify-center">
              <ImageWrap {...props.image} objectFit="contain" loading="eager" className="block" />
            </div>
          )}
        </div>

        {props.logo && (
          <div className="ml-6 mt-8 absolute lg:hidden">
            <ImageWrap {...props.logo} objectFit="contain" loading="eager" className="block" />
          </div>
        )}
        {props.inPageLinks && props.inPageLinks.length > 0 && (
          <div className="container px-6 -ml-5 lg:mx-auto text-center z-1 relative">
            <Links links={props.inPageLinks} />
          </div>
        )}
      </div>
    </>
  )
}
