import React from 'react'
import { WhiteButton } from '@truphone/buttons'
import { HeadingH3Light } from './Typography'
import './floating-block.css'

export default function FloatingBlock(props) {
  return (
    <div
      className="lg:-mb-20"
      style={{ background: props.backgroundColor ? props.backgroundColor.hex : null }}
    >
      <div className="lg:container mx-auto lg:px-4 transform lg:top-1/2 lg:-translate-y-1/2">
        <div className="text-white justify-center lg:justify-between text-center lg:text-left flex flex-wrap lg:flex-nowrap py-10 px-8 lg:p-12 lg:rounded-lg floating-gradient">
          <div className="lg:pr-12 max-w-4xl">
            {props.title && (
              <h3 className="text-3xl-custom leading-snug tracking-thighter mb-4">{props.title}</h3>
            )}
            {props.subtitle && <HeadingH3Light>{props.subtitle}</HeadingH3Light>}
          </div>
          {props.cta && props.cta.length > 0 && (
            <WhiteButton
              className="mt-8 lg:mt-auto lg:whitespace-nowrap w-full sm:w-auto my-auto"
              href={props.cta[0].href}
              title={props.cta[0].title}
              target={props.cta[0].openTab ? '_blank' : ''}
            >
              {props.cta[0].text}
            </WhiteButton>
          )}
        </div>
      </div>
    </div>
  )
}
