import { SecondaryButton, ArrowButton } from '@truphone/buttons'
import { uppercaseString } from '@truphone/utils'
import { ImageWrap, LinkWrap } from '@truphone/gatsby-wrappers'
import React from 'react'
import '../style.css'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const CardItem = (props, box, i, cta, list) => {
  return (
    <div
      key={`shadow-box-${i}`}
      className={`w-full ${props.highlightBox && i > 0 ? 'mt-10 lg:mt-0' : ''} ${
        list.length % 2 == 0 && !props.carousel
          ? 'lg:w-1/2'
          : list.length % 3 === 0 && !props.carousel
          ? 'lg:w-1/3'
          : ''
      } ${props.highlightBox ? 'px-5' : ''} ${props.carousel ? 'p-4 pb-8 lg:w-1/3' : 'p-4'} ${
        props.centered ? 'text-center' : props.carousel ? '' : ''
      }`}
    >
      <div
        className={`${props.highlightBox ? '' : 'bg-white rounded-2xl overflow-hidden shadow-xl'} ${
          props.hoverBlue
            ? 'relative cursor-pointer hover:bg-blue-300 hover:text-white hover-text-white'
            : ''
        } h-full flex flex-col ${
          props.ipadLayout
            ? 'sm:flex-row md:flex-col'
            : props.carousel
            ? 'md:flex-row lg:flex-col'
            : 'sm:flex-row md:flex-col'
        }`}
      >
        {props.hoverBlue && (
          <LinkWrap href={cta.href} className="z-0 absolute w-full h-full"></LinkWrap>
        )}
        {!props.icons && box.image && (
          <div
            className={`relative ${
              props.highlightBox ? '' : props.carousel ? 'lg:h-80' : 'flex items-center shrink-0' //pt-6 px-6
            } ${
              !props.carousel && props.imagesNotStretched
                ? 'px-6 pt-6 h-32'
                : props.carousel
                ? 'md:w-1/3 lg:w-full'
                : 'sm:w-1/3 md:w-full'
            } overflow-hidden`}
          >
            <ImageWrap
              className={`${
                !props.carousel && props.imagesNotStretched
                  ? ''
                  : !props.carousel
                  ? 'object-cover w-full h-full'
                  : 'object-cover w-full h-full md:w-auto lg:w-full md:absolute lg:relative left-0 max-w-none'
              }`}
              {...box.image}
            />
          </div>
        )}
        {props.icons && (
          <div className={`relative mt-8 ${props.centered ? 'mx-auto' : 'mx-6'} w-15 h-15`}>
            <ImageWrap className="w-full h-full" {...box.image} />
          </div>
        )}
        <div
          className={`${
            props.centered || !props.carousel ? '' : 'flex flex-col md:w-2/3 lg:w-full'
          } ${props.ipadLayout ? 'sm:w-2/3 md:w-full' : 'w-full'}  md:h-full lg:h-auto`}
        >
          <div
            className={`${props.highlightBox ? 'py-6' : props.centered ? 'px-6 py-8' : 'p-6'}
                          ${box.icon ? 'pt-4' : ''}`}
          >
            {box.topTitle && (
              <p className={`overline-text mb-4 text-grey-700`}>{uppercaseString(box.topTitle)}</p>
            )}
            {box.title && props.highlightBox ? (
              <h3 className="text-xl lg:text-2xl pb-4 mb-4 border-b border-white border-opacity-20 font-medium">
                {box.title}
              </h3>
            ) : (
              box.title && <h4 className="text-xl font-medium mb-4">{box.title}</h4>
            )}

            {box.content && (
              <div
                className={`text-base ${props.centered ? 'leading-loose' : ''} ${
                  props.highlightBox
                    ? 'text-gray-100 opacity-60'
                    : props.hoverBlue
                    ? 'opacity-75'
                    : 'text-grey-700'
                } p-margin list-tick list-tick-blue`}
                dangerouslySetInnerHTML={{ __html: box.content }}
              />
            )}
          </div>
          {cta && (
            <div
              className={`${
                props.carousel ? 'lg:hidden' : 'md:hidden'
              } p-6 mt-auto text-base text-blue-100 font-medium`}
            >
              <ArrowButton href={cta.href} target={cta.openTab ? '_blank' : null}>
                {cta.text}
              </ArrowButton>
            </div>
          )}
        </div>
        {cta && (
          <div
            className={`hidden ${props.carousel ? 'lg:block' : 'md:block'} 
              p-6 pt-0 mt-auto text-blue-100 text-lg font-medium`}
          >
            <ArrowButton href={cta.href} target={cta.openTab ? '_blank' : null}>
              {cta.text}
            </ArrowButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default function CardContainer(props) {
  const containerCta = props.cta && props.cta.length > 0 && props.cta[0]
  return (
    <div className={`overflow-hidden py-10 ${props.largePaddingTop ? 'pt-24' : ''}`}>
      <div className={`container mx-auto ${props.carousel ? '' : 'px-2'} lg:px-6`}>
        <div
          className={`${
            props.highlightBox ? 'p-10 business-gradient text-white rounded-2xl' : ''
          } ${props.carousel ? 'hidden md:block' : ''}`}
        >
          {props.cards && (
            <div
              className={`flex flex-wrap ${props.centered ? 'justify-center' : ''} ${
                props.highlightBox ? '-mx-5' : props.carousel ? 'lg:-mx-4' : 'md:-mx-4'
              }
              `}
            >
              {props.cards.map((box, i, list) => {
                const cta = box.cta && box.cta.length > 0 && box.cta[0]
                return CardItem(props, box, i, cta, list)
              })}
            </div>
          )}
        </div>
        {props.carousel && (
          <div className="relative md:hidden">
            <CarouselProvider
              infinite={true}
              naturalSlideWidth={350}
              naturalSlideHeight={500}
              isIntrinsicHeight={true}
              totalSlides={props.cards.length}
              isPlaying={true}
            >
              <Slider>
                {props.cards &&
                  props.cards.map((box, i, list) => {
                    const cta = box.cta && box.cta.length > 0 && box.cta[0]
                    return (
                      <Slide
                        index={i}
                        className={`w-full ${props.carousel ? 'p-0 lg:p-8' : 'p-8'}`}
                        key={`card-item-${i}`}
                      >
                        {CardItem(props, box, i, cta, list)}
                      </Slide>
                    )
                  })}
              </Slider>
              {props.cards && (
                <div className="mt-2 flex justify-center">
                  {props.cards.map((item, i) => {
                    return (
                      <Dot
                        className={`w-4 h-4 m-2 flex justify-center items-center dot-hover`}
                        key={`slide-dot-${i}`}
                        slide={i}
                      >
                        <span className="w-1 h-1 bg-black rounded-full "></span>
                      </Dot>
                    )
                  })}
                </div>
              )}
            </CarouselProvider>
          </div>
        )}
      </div>

      {containerCta && (
        <div className="py-10 flex justify-center">
          <SecondaryButton
            className={`w-full mx-4 lg:mx-auto  lg:w-auto`}
            href={containerCta.href}
            target={containerCta.openTab ? '_blank' : null}
          >
            {containerCta.text}
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}
