import React, { useState, useEffect } from 'react'
import { OutlinedButtonBlack } from '@truphone/buttons'
import loadable from '@loadable/component'

const Form = loadable(() => import('./Form'))

export default function FormContainerModal(props) {
  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const shouldOpenModal = () => {
      if (window.location.hash.substring(1) === props.modalId) {
        handleOpen()
      }
    }

    window.addEventListener('hashchange', shouldOpenModal, false)
    shouldOpenModal()

    return () => {
      window.removeEventListener('hashchange', shouldOpenModal, false)
    }
  }, [props.modalId])

  const handleOpen = () => {
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = 20
      pageMain.style.overflow = 'hidden'
      setOpen(true)
      setSubmitted(false)
    }
  }

  const handleClose = () => {
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      if (window.history.replaceState) {
        window.history.replaceState({}, '', window.location.pathname + window.location.search)
      } else {
        window.location.hash = ''
      }

      pageMain.style.zIndex = '0'
      pageMain.style.overflow = 'auto'
      setOpen(false)
    }
  }
  return (
    <>
      {open && (
        <div className="fixed z-50 top-0 left-0 w-full min-h-full">
          <div
            className={`fixed w-full h-screen -z-1 ${
              !props.backgroundColor ? 'bg-blue-900 bg-opacity-80' : ''
            } select-none`}
            onClick={handleClose}
            style={{ background: props.backgroundColor && props.backgroundColor.hex }}
          ></div>
          <div className="h-screen overflow-y-auto relative z-1">
            <div className="relative flex justify-center py-10  mx-2">
              <div
                className={`bg-grey-100 relative rounded-2xl p-10 w-full ${
                  submitted ? 'max-w-xl' : 'max-w-790'
                }`}
              >
                <div
                  onClick={handleClose}
                  className="text-center text-3xl w-8 h-8 text-black cursor-pointer absolute top-2 right-4 z-1"
                >
                  &#215;
                </div>

                {props.title && !submitted && (
                  <h2 className="text-xl font-medium mb-4">{props.title}</h2>
                )}
                {props.content && !submitted && (
                  <div
                    className="text-grey-700 text-base p-margin link-style-color hover-no-border mb-8"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                  />
                )}
                <Form
                  {...props.form}
                  thankYouContent={props.form.thankYouContentNode?.childMarkdownRemark?.html}
                  fullWidthCta={true}
                  secondaryCta
                  onSubmitted={() => setSubmitted(true)}
                />
                {submitted && (
                  <OutlinedButtonBlack
                    className={`mt-14 w-full lg:text-base`}
                    href="#"
                    onClick={handleClose}
                  >
                    {props.form?.closeText || 'Close'}
                  </OutlinedButtonBlack>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
