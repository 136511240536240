import React from 'react'
import '../style.css'
import cx from 'classnames'
import { SecondaryButton } from '@truphone/buttons'

export default function Block({ className, ...props }) {
  className = cx('bg-blue-900 text-white text-center py-10 lg:py-20', className)
  return (
    <div className={className}>
      <div className="container mx-auto px-6">
        <h3 className="text-2xl lg:text-4xl font-normal mb-9">{props.title}</h3>
        <div
          className="text-base lg:text-2xl mx-auto max-w-3xl font-light"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
        {props.cta && (
          <div className="mt-12">
            {props.cta.map((cta, i) => {
              return (
                <SecondaryButton
                  targer={cta.openTab ? '_blank' : ''}
                  key={`block-cta${i}`}
                  className="lg:min-w--250"
                  href={cta.href}
                >
                  {cta.text}
                </SecondaryButton>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
