import React from 'react'
import '../style.css'
import Title from './Title'

export default function BlockIconsText(props) {
  return (
    <div
      id={props.id}
      className={`pt-20 lg:pt-24 pb-10 ${props.extraPaddingBottom ? 'lg:pb-48' : 'lg:pb-20'} ${
        props.backgroundImage ? 'bg-cover' : ''
      }`}
      style={{ backgroundImage: props.backgroundImage ? `url(${props.backgroundImage.url})` : '' }}
    >
      <Title className="mb-12 lg:mb-12" title={props.title} subtitle={props.subtitle} />
      <div className="container mx-auto px-4">
        {props.items && (
          <div className="-mx-5 flex flex-wrap">
            {props.items.map((item, i) => {
              return (
                <div
                  key={`item-${i}`}
                  className="flex w-full lg:w-1/2 p-5 lg:py-12 flex-col sm:flex-row"
                >
                  <div className="w-1/4 pb-6 sm:pb-0 sm:pr-8 flex">
                    <img
                      className="max-w-full sm:m-auto lg:ml-0"
                      src={item.image.src}
                      alt={item.image.alt}
                    />
                  </div>
                  <div className="w-full sm:w-2/3">
                    <h5 className="font-bold text-lg mb-2">{item.title}</h5>
                    <p>{item.content}</p>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
