import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import BannedEmailDomains from './BannedEmailDomains'
import Select from './Select'
import Tooltip from './Tooltip'

export default function Field(props) {
  const [focus, setFocus] = useState(false)

  const {
    register,
    control,
    watch,
    formState: { errors }
  } = useFormContext()

  const rules = {
    required: props.required ? props.requiredMessage : false
  }

  if ((props.type === 'Text' || props.type === 'Email') && props.maxLength) {
    rules.maxLength = {
      value: props.maxLength,
      message: props.maxLengthMessage
    }
  }

  if (props.type === 'Email' && props.blockFreeEmailDomains) {
    rules.validate = (value) => {
      const domain = value.substring(value.indexOf('@') + 1)
      if (BannedEmailDomains.includes(domain)) {
        return props.blockFreeEmailDomainsMessage
      }
    }
  }

  if ((props.type === 'Text' || props.type === 'Email') && props.pattern) {
    rules.pattern = { value: new RegExp(props.pattern), message: props.patternMessage }
  }

  if (props.type === 'Number' && props.minValue) {
    rules.min = {
      value: props.minValue,
      message: props.minValueMessage ? props.minValueMessage : 'Minimum is ' + props.minValue
    }
  }

  if (props.type === 'Number' && props.maxValue) {
    rules.max = {
      value: props.maxValue,
      message: props.maxValueMessage ? props.maxValueMessage : 'Maximum is ' + props.maxValue
    }
  }

  if (props.type === 'Date' && props.minDate) {
    rules.min = {
      value: props.minDate,
      message: props.minDateMessage ? props.minDateMessage : 'Minimum is ' + props.minDate
    }
  }

  if (props.type === 'Date' && props.maxDate) {
    rules.max = {
      value: props.maxDate,
      message: props.maxDateMessage ? props.maxDateMessage : 'Maximum is ' + props.maxDate
    }
  }

  const hasValue = watch(props.name)
  return (
    <>
      <label
        htmlFor={props.name}
        className={`${
          focus ? 'text-black' : errors[props.name] ? 'text-pink' : ''
        } 'text-sm inline-block mb-1`}
      >
        {props.label}
        {props.required ? (
          <span className={`${errors[props.name] ? 'text-pink' : 'text-black'}`}> *</span>
        ) : (
          ''
        )}
      </label>
      <fieldset
        className={`relative rounded-lg w-full ${
          focus
            ? 'border-black border-2'
            : errors[props.name]
            ? 'border-pink border text-pink'
            : 'border-gray-400 border'
        }
        ${props.disabled ? '' : 'hover:border-black'}`}
      >
        {(props.type === 'Text' || props.type === 'Email' || props.type === 'Tel') && (
          <input
            type={'text'}
            disabled={props.disabled}
            name={props.name}
            placeholder={props.placeholder}
            {...register(props.name, {
              ...rules,
              onFocus: () => setFocus(true),
              onBlur: () => setFocus(false)
            })}
            className={`${props.icon ? 'icon pl-9 pr-3' : 'px-3'} ${
              hasValue && !errors[props.name] && !props.tooltip ? 'filled pr-7' : ''
            }  ${props.tooltip ? 'pr-8' : ''} 
              ${props.disabled ? 'opacity-50' : ''}
              border-none rounded-lg text-black bg-white py-3 block w-full appearance-none focus:outline-none focus:ring-0`}
          />
        )}
        {props.type === 'Select' && (
          <Controller
            name={props.name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onClick={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                icon={props.icon?.url}
                options={props.selectFieldOptions}
                textAsValue={props.textAsValue}
              ></Select>
            )}
            rules={rules}
          />
        )}

        {props.type === 'Date' && (
          <input
            type="date"
            {...register(props.name, {
              ...rules,
              onFocus: () => setFocus(true),
              onBlur: () => setFocus(false)
            })}
            name={props.name}
            className={`${
              props.icon ? 'icon pl-9 pr-3' : 'px-3'
            } date rounded-lg py-3 leading-none block w-full mt-0 border-none appearance-none focus:outline-none focus:ring-0`}
          />
        )}
        {props.type === 'Number' && (
          <input
            type="number"
            {...register(props.name, {
              ...rules,
              onFocus: () => setFocus(true),
              onBlur: () => setFocus(false)
            })}
            name={props.name}
            className={`${props.icon ? 'icon pl-9 pr-3' : 'px-3'} ${
              hasValue && !errors[props.name] && !props.tooltip ? 'filled pr-7' : ''
            } ${props.tooltip ? 'pr-8' : ''} 
              bg-white rounded-lg py-3 leading-none block w-full mt-0 border-none appearance-none focus:outline-none focus:ring-0`}
          />
        )}

        {props.type === 'TextArea' && (
          <textarea
            rows="3"
            {...register(props.name, {
              ...rules,
              onFocus: () => setFocus(true),
              onBlur: () => setFocus(false)
            })}
            name={props.name}
            className={`${props.icon ? 'icon pl-9 pr-3' : 'px-3'} 
              ${props.tooltip ? 'pr-8' : ''} 
              ${hasValue ? 'filled' : ''}
              bg-white rounded-lg py-2 block w-full mt-0 border-none appearance-none focus:outline-none focus:ring-0 `}
          ></textarea>
        )}

        {props.icon && (
          <img src={props.icon.url} alt="" className="z-1 w-5 absolute top-4 left-2 select-none" />
        )}

        {props.tooltip && <Tooltip tooltip={props.tooltip} type={props.type} />}
        {hasValue &&
          !errors[props.name] &&
          !props.tooltip &&
          props.type !== 'Select' &&
          props.type !== 'TextArea' && (
            <img
              src="/images/icons/icon-action-tick.svg"
              className={`w-5 absolute tick-position select-none`}
              style={{ right: props.type == 'Date' ? '36px' : null }}
              alt="valid"
            />
          )}
      </fieldset>
      {errors[props.name] && (
        <span className="relative text-xs text-pink" id="error">
          {errors[props.name].message}
        </span>
      )}
    </>
  )
}
