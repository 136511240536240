import React, { useState } from 'react'

function Checkbox(props) {
  const [value, setValue] = useState(false)
  const [touched, setTouched] = useState(false)
  return (
    <div className="relative">
      <div className="flex flex-row-reverse items-center">
        <label className="relative z-0 w-full p-2 inline-flex items-center link-underline">
          <span
            className={`ml-2 text-sm ${
              props.required && !value && touched ? 'text-pink' : 'text-black opacity-60'
            }`}
            dangerouslySetInnerHTML={{ __html: props.label }}
          />
        </label>
        <input
          checked={value}
          className="text-blue-100 form-checkbox rounded-sm"
          type={props.type}
          onChange={() => {
            setTouched(true)
            setValue(!value)
          }}
        />
      </div>
      {props.validationMessage && props.required && !value && touched && (
        <span className="ml-2 relative text-xs text-pink" id="error">
          {props.validationMessage}
        </span>
      )}
    </div>
  )
}

export default Checkbox
