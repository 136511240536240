import React from 'react'
import { OutlinedButtonWhite, PrimaryButton, SecondaryButton } from '@truphone/buttons'

export default function SimpleBanner(props) {
  const leftAligned =
    props.leftAlignedAbsolute ||
    props.leftAligned ||
    props.lines ||
    props.imageCircle ||
    props.linesFullWidth ||
    props.backgroundFull

  const full = props.backgroundFull
  const iconsGroup = props.icons && props.icons.length > 0

  return (
    <div
      className={`relative ${props.linesFullWidth ? 'border-y border-grey-200' : ''}`}
      style={{ background: props.backgroundGradient }}
    >
      {props.backgroundImage && full && (
        <img
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover ${
            leftAligned ? 'h-full w-full' : ''
          }`}
          src={props.backgroundImage.url}
          alt={props.backgroundImage.alt || ''}
        />
      )}
      <div
        className={`relative container mx-auto ${
          props.paddingOuterTopBottom ? 'py-10 lg:py-20' : ''
        } px-4 lg:px-6`}
      >
        <div
          className={`relative ${props.backgroundImage ? 'text-white bg-cover' : 'text-black'} ${
            props.backgroundImage && !full ? 'overflow-hidden rounded-2xl' : ''
          } 
          ${full ? '' : props.paddingInnerTopBottom ? 'py-8 xl:p-10' : 'xl:px-10'} px-4  
          ${props.lines && !props.linesFullWidth ? 'border-y border-grey-200' : ''}`}
          style={{ backgroundColor: props.backgroundColor }}
        >
          {props.backgroundImage && !full && (
            <img
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover ${
                leftAligned ? 'h-full w-full' : ''
              }`}
              src={props.backgroundImage.url}
              alt={props.backgroundImage.alt || ''}
            />
          )}

          <div
            className={`relative z-1 ${
              props.leftAlignedAbsolute
                ? 'md:flex justify-between'
                : leftAligned
                ? 'xl:flex justify-between'
                : 'text-center'
            }`}
          >
            <div
              className={`${
                iconsGroup
                  ? 'xl:flex items-center text-center xl:text-left xl:pr-8 xl:w-1/3'
                  : props.leftAlignedAbsolute
                  ? 'text-center md:text-left md:flex items-center'
                  : leftAligned
                  ? 'xl:flex items-center text-center xl:text-left xl:pr-8'
                  : ''
              } ${leftAligned && props.ctas && props.ctas.length > 0 ? 'max-w-4xl' : ''}`}
            >
              {props.image && (
                <div
                  className={`${
                    props.leftAlignedAbsolute
                      ? 'h-20 md:h-16 invisible '
                      : leftAligned
                      ? 'mb-4 mx-auto xl:ml-0 xl:mb-auto'
                      : ''
                  } my-auto  ${
                    props.imageCircle ? 'bg-white shadow-sm w-16 h-16 rounded-full' : ''
                  } flex justify-center items-center shrink-0 xl:mr-5 `}
                >
                  <img src={props.image.url} alt="" />
                </div>
              )}
              {leftAligned ? (
                <div>
                  <h3 className={`${props.lines ? 'text-3xl-custom leading-tight' : 'text-2xl'}`}>
                    {props.title}
                  </h3>
                  {props.subtitle && (
                    <p className={`${props.lines ? 'text-2xl font-light' : 'text-base'} mt-2`}>
                      {props.subtitle}
                    </p>
                  )}

                  {props.content && (
                    <p className={`${iconsGroup ? 'text-sm' : 'text-base'} mt-2 mb-2`}>
                      {props.content}
                    </p>
                  )}
                </div>
              ) : (
                <h3 className="text-3xl-custom font-medium">{props.title}</h3>
              )}
            </div>
            {iconsGroup && (
              <div className="flex flex-col sm:flex-row mt-4 xl:mt-0 xl:w-2/3">
                {props.icons.map((icon, i) => {
                  return (
                    <div
                      key={`banner-icon-${i}`}
                      className="flex flex-col sm:flex-row mt-6 sm:mt-0 items-center px-4"
                    >
                      <img src={icon.icon?.url} className={`mb-2 sm:mr-4`} />
                      <div
                        className="text-sm text-center sm:text-left"
                        dangerouslySetInnerHTML={{
                          __html: icon.textNode?.childMarkdownRemark?.html
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )}
            {props.ctas && props.ctas.length > 0 && (
              <div
                className={`shrink-0 flex flex-wrap  ${
                  props.leftAlignedAbsolute
                    ? 'lg:ml-4 my-auto justify-center mt-4 lg:mt-0'
                    : leftAligned
                    ? 'justify-center xl:justify-end my-auto mt-6 xl:mt-auto'
                    : 'justify-center mt-4 -mb-3'
                }
                ${props.ctas && props.ctas.length > 1 ? 'xl:-mx-3' : ''}`}
              >
                {props.ctas.map((cta, i) => {
                  return props.ctas.length > 1 && i === 0 ? (
                    <OutlinedButtonWhite
                      key={`banner-button-${i}`}
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                      className={`${
                        leftAligned ? 'mx-1 my-2 xl:m-2' : 'm-3'
                      } w-full sm:w-auto shrink-0`}
                    >
                      {cta.text}
                    </OutlinedButtonWhite>
                  ) : props.lines ? (
                    <PrimaryButton
                      key={`banner-button-${i}`}
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                      className={`${
                        leftAligned ? 'mx-1 my-2 xl:m-2' : 'm-3'
                      } w-full sm:w-auto shrink-0`}
                    >
                      {cta.text}
                    </PrimaryButton>
                  ) : (
                    <SecondaryButton
                      key={`banner-button-${i}`}
                      href={cta.href}
                      target={cta.openTab ? '_blank' : ''}
                      className={`${
                        leftAligned ? 'mx-1 my-2 xl:m-2' : 'm-3'
                      } w-full sm:w-auto shrink-0`}
                    >
                      {cta.text}
                    </SecondaryButton>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        {props.image && props.leftAlignedAbsolute && (
          <div
            className={`max-w-[10rem] lg:max-w-none absolute -top-10 left-1/2 -translate-x-1/2 md:top-4 md:translate-x-0 md:left-12`}
          >
            <img src={props.image.url} alt="" />
          </div>
        )}
      </div>
    </div>
  )
}
