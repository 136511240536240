import React, { useState } from 'react'

import { Arrow } from '@truphone/icons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { SimpleModal } from '@truphone/modals'
export default function Team(props) {
  const [isOpen, setIsOpen] = useState(null)
  const handleOpen = (e, i) => {
    e.preventDefault()
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = 2147483641
      setIsOpen(i)
    }
  }
  return (
    <div className="container mx-auto pt-6">
      {props.team && (
        <div className="flex flex-wrap justify-center">
          {props.team.map((profile, i) => {
            return (
              <a
                href={profile.link}
                className="cursor-pointer link-hover flex flex-col p-6 mb-10 hover:shadow-team text-center w-full hover-text-black"
                key={`team-profile-${i}`}
                style={{ maxWidth: 300 }}
                onClick={(e) => handleOpen(e, i)}
              >
                {profile.image && (
                  <div className="mx-auto w-48 h-48 rounded-full overflow-hidden">
                    <ImageWrap className="w-full img-hover" src={profile.image.url} />
                  </div>
                )}
                {profile.name && <h3 className="text-2xl mt-8 mb-2">{profile.name}</h3>}
                {profile.role && <p className="text-base mb-8">{profile.role}</p>}

                <div
                  className={
                    'arrow-link relative items-center m-auto mb-0 text-lg font-medium inline-flex text-blue-100 hover:text-black'
                  }
                >
                  {props.linkText}
                  <Arrow className="-rotate-90 ml-2 w-4 h-6 arrow" />
                </div>
              </a>
            )
          })}
        </div>
      )}
      {props.team.map((profile, i) => {
        return (
          <SimpleModal
            key={`modal-${i}`}
            isOpen={isOpen === i}
            setClose={() => {
              setIsOpen(null)
            }}
          >
            <h3 className="text-2xl mb-4 text-left">{profile.name}</h3>
            <div
              className="text-base p-margin text-left whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: profile.bio }}
            />
          </SimpleModal>
        )
      })}
    </div>
  )
}
