import { LinkWrap } from '@truphone/gatsby-wrappers'
import React from 'react'
import '../style.css'
import NewsCard from './NewsCard'

export default function RelatedArticles(props) {
  return (
    <div className={`py-10 ${props.largePaddingTop ? 'pt-24' : props.noPaddingTop ? 'pt-0' : ''}`}>
      <div className="container mx-auto px-6">
        <div className="rounded-2xl">
          {props.articles && (
            <div className={`flex flex-wrap -mx-4`}>
              {props.articles.map((article, i, list) => {
                return (
                  <div
                    key={`shadow-box-${i}`}
                    className={`overflow-hidden relative w-full  ${
                      list.length % 2 == 0 ? 'lg:w-1/2' : list.length % 3 === 0 ? 'lg:w-1/3' : ''
                    } p-4`}
                  >
                    <LinkWrap
                      href={article.link}
                      className="z-1 absolute w-full h-full hover-card"
                    ></LinkWrap>
                    <NewsCard key={`news-card-${i}`} fullHeight {...article} />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
