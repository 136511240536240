import React from 'react'
import { uppercaseString } from '@truphone/utils'
import { ArrowButton, OutlinedButtonWhite, SecondaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function BannerCore(props) {
  return (
    <div className={`relative overflow-hidden text-center py-16`}>
      {props.backgroundImage && (
        <div className="block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
          <ImageWrap
            className="block w-full h-full object-cover"
            {...props.backgroundImage}
            loading="eager"
          />
        </div>
      )}
      <div className={`max-w-5xl px-6 mx-auto z-1 relative text-white`}>
        {props.topTitle && (
          <h2 className="text-xs md:text-base font-medium tracking-widest mb-4 ">
            {uppercaseString(props.topTitle)}
          </h2>
        )}
        {props.title && <h2 className="text-3xl lg:text-4xl mb-4">{props.title}</h2>}
        {props.content && (
          // ${props.topTitle ? '' : 'lg:text-2xl '}
          <p className={`text-base mt-3 mb-4 leading-normal lg:whitespace-pre-wrap`}>
            {props.content}
          </p>
        )}
        {props.ctas && (
          <div className="flex flex-wrap -mx-2 justify-center items-center">
            {props.ctas.map((cta, i) => {
              return props.linkArrow ? (
                <ArrowButton href={cta.href} className={`text-base lg:text-lg m-2 mt-4 lg:mt-6`}>
                  {cta.text}
                </ArrowButton>
              ) : i === 0 ? (
                <SecondaryButton
                  className={`w-full sm:w-auto text-base lg:text-lg m-2 mt-4 lg:mt-6`}
                  href={cta.href}
                >
                  {cta.text}
                </SecondaryButton>
              ) : (
                <OutlinedButtonWhite
                  className={`w-full sm:w-auto text-base lg:text-lg m-2 mt-4 lg:mt-6`}
                  href={cta.href}
                >
                  {cta.text}
                </OutlinedButtonWhite>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
