import React from 'react'
import { ArrowButton, PrimaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

export default function RelatedItem(props) {
  return (
    <div
      className={`mx-auto px-3 max-w-5xl relative ${
        props.related ? 'border-b border-gray-200' : ''
      } py-10 overflow-hidden`}
    >
      {props.image && (
        <a
          className="relative hidden md:block float-right max-w-xs  rounded-sm shadow overflow-hidden ml-8 mb-6"
          href={props.ctas && props.ctas.length > 0 && props.ctas[0].href}
        >
          <ImageWrap className="object-cover h-full mx-auto" {...props.image} />
        </a>
      )}
      {props.date && (
        <p className="text-xs text-gray-700 mb-4">
          {props.date}
          {props.readTime ? '·' + props.readTime : ''}
        </p>
      )}
      {props.title && props.ctas.length > 0 && props.ctas[0].href && (
        <h3 className="text-2xl mb-4">
          <a className="hover-blue-line" href={props.ctas[0].href}>
            {props.title}
          </a>
        </h3>
      )}
      {props.subtitle && <h4 className="text-base mb-4 block">{props.subtitle}</h4>}
      {props.content && (
        <div
          className="text-sm p-margin mb-8"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      )}
      {props.image && props.ctas.length > 0 && props.ctas[0].href ? (
        <a
          className="relative inline-flex justify-center md:hidden rounded-sm shadow overflow-hidden mb-4"
          href={props.ctas[0].href}
        >
          <ImageWrap className="w-auto" {...props.image} />
        </a>
      ) : (
        props.image && <ImageWrap className="w-auto" {...props.image} />
      )}

      {props.ctas && (
        <div className="flex flex-wrap mt-8 items-center">
          {props.ctas.map((cta, i) => {
            return cta.primary ? (
              <PrimaryButton key={`related-cta-${i}`} className="my-2 mr-2" href={cta.href}>
                {cta.text}
              </PrimaryButton>
            ) : (
              <ArrowButton
                className={'my-2 text-blue-100 hover:text-blue-100"'}
                key={`related-cta-${i}`}
                href={cta.href}
              >
                {cta.text}
              </ArrowButton>
            )
          })}
        </div>
      )}
    </div>
  )
}
