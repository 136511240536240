import React from 'react'
import { ArrowButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'

const LogoImages = (props) => {
  const maxHeight = Math.max(...props.logos.map((x) => x.height))
  return (
    <div
      className={`${props.lines ? 'border-y border-gray-400' : ''} ${props.noWrap ? 'w-full' : ''}`}
      style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent' }}
    >
      <div
        className={`logos-container transition-all duration-500 py-10 container mx-auto px-4 flex ${
          props.noWrap ? 'flex-nowrap justify-start lg:justify-around' : 'flex-wrap justify-around'
        }  items-center `}
      >
        {props.logos.map((logo, i) => {
          return (
            <div
              className={`${props.noWrap ? 'shrink-0' : ''} m-2 max-h-16`}
              key={`logo-${i}`}
              style={{ maxWidth: `${props.imageMaxWidth}px`, maxHeight: `${maxHeight}px` }}
            >
              <ImageWrap {...logo} loading="lazy" className="w-full h-full object-contain" />
            </div>
          )
        })}
      </div>
      {props.ctaSeeAll && (
        <div
          className={`container mx-auto px-4 flex justify-center pb-10 ${
            props.lines ? '' : 'lg:pb-20'
          }`}
        >
          <ArrowButton
            className="text-blue-100 text-xl items-center font-medium"
            href={props.ctaSeeAll.href}
          >
            {props.ctaSeeAll.text}
          </ArrowButton>
        </div>
      )}
    </div>
  )
}

export default function Logos(props) {
  return props.logos && <LogoImages {...props} />
}
