import React from 'react'
import RelatedArticles from './RelatedArticles'
import ThankYouMessage from './ThankYouMessage'

function GatedThankYouContent(props) {
  return (
    <div className="pt-10">
      <ThankYouMessage
        icon={props.icon}
        socialsTitle={props.socialsTitle}
        topTitle={props.topTitle}
        title={props.title}
        content={props.subtitle}
      />
      {props.relatedTitle && (
        <div className="mt-20">
          <h3 className="text-center text-3xl-custom font-semibold -mb-4">{props.relatedTitle}</h3>
          <RelatedArticles {...props.related} />
        </div>
      )}
    </div>
  )
}

export default GatedThankYouContent
