import React from 'react'
import { uppercaseString } from '@truphone/utils'

function TwoItemTextBlock(props) {
  return (
    <div className="relative py-10 lg:py-20 border-b">
      <div
        className="flex flex-col lg:flex-row container mx-auto px-4 lg:px-20"
        style={{ color: props.textColor?.hex }}
      >
        <div className="lg:w-1/2">
          <p className="overline-muted mb-4" style={{ color: props.textColor?.hex }}>
            {uppercaseString(props.topTitle)}
          </p>
          <h2 className="text-2xl lg:text-3xl md:font-semibold whitespace-pre-wrap">
            {props.title}
          </h2>
          <div
            className={`body1-muted link-blue mt-6 mx-auto ${
              props.listStyle === 'squares' ? 'list-square' : ''
            }`}
            style={{ color: props.textColor?.hex }}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </div>
        <div className="lg:w-1/2 lg:pl-12">
          <div
            className={`body1-muted link-blue mt-6 mx-auto ${
              props.listStyle === 'squares' ? 'list-square' : ''
            }`}
            style={{ color: props.textColor?.hex }}
            dangerouslySetInnerHTML={{ __html: props.contentOther }}
          />
        </div>
      </div>
    </div>
  )
}

export default TwoItemTextBlock
