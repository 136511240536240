import React from 'react'
import cx from 'classnames'
import { uppercaseString } from '@truphone/utils'

export function HeadingH1({ className, children, ...props }) {
  className = cx('heron text-6xl text-6xl-resp', className)
  return (
    <h1 className={className} {...props}>
      {uppercaseString(children)}
    </h1>
  )
}

export function HeadingH1Large({ className, children, ...props }) {
  className = cx('heron text-7xl text-7xl-resp', className)
  return (
    <h1 className={className} {...props}>
      {children}
    </h1>
  )
}

export function HeadingH2({ className, children, ...props }) {
  className = cx('h3-light', className)
  return (
    <h2 className={className} {...props}>
      {children}
    </h2>
  )
}

export function HeadingH3({ className, children, ...props }) {
  className = cx('text-xl lg:text-2xl', className)
  return (
    <h3 className={className} {...props}>
      {children}
    </h3>
  )
}

export function HeadingH3Light({ className, children, ...props }) {
  className = cx('h5-light', className)
  return (
    <h3 className={className} {...props}>
      {children}
    </h3>
  )
}

export function HeadingH3Small({ className, children, ...props }) {
  className = cx('text-2xl lg:text-3xl-custom leading-snug tracking-thighter', className)
  return (
    <h3 className={className} {...props}>
      {children}
    </h3>
  )
}
export function HeadingH4({ className, children, ...props }) {
  className = cx('text-xl', className)
  return (
    <h4 className={className} {...props}>
      {children}
    </h4>
  )
}
export function HeadingH4Bold({ className, children, ...props }) {
  className = cx('text-xl font-semibold', className)
  return (
    <h4 className={className} {...props}>
      {children}
    </h4>
  )
}

export function HeadingH4Light({ className, children, ...props }) {
  className = cx('text-xl font-light', className)
  return (
    <h4 className={className} {...props}>
      {children}
    </h4>
  )
}

export function HeadingH5({ className, children, ...props }) {
  className = cx('overline-text', className)
  return (
    <h5 className={className} {...props}>
      {children}
    </h5>
  )
}
export function HeadingH5Light({ className, children, ...props }) {
  className = cx('text-base font-light', className)
  return (
    <h5 className={className} {...props}>
      {children}
    </h5>
  )
}
export function HeadingH5Underline({ className, children, ...props }) {
  className = cx('a-link-style inline text-sm font-bold tracking-wide', className)
  return (
    <h5 className={className} {...props}>
      {children}
    </h5>
  )
}
export function HeadingH6({ className, children, ...props }) {
  className = cx('text-xl font-bold', className)
  return (
    <h6 className={className} {...props}>
      {children}
    </h6>
  )
}
export function Body({ className, children, ...props }) {
  className = cx('text-base', className)
  return (
    <p className={className} {...props}>
      {children}
    </p>
  )
}
export function BodySmall({ className, children, ...props }) {
  className = cx('text-xs opacity-75 tracking-wide', className)
  return (
    <p className={className} {...props}>
      {children}
    </p>
  )
}
