import React from 'react'
import { uppercaseString } from '@truphone/utils'

export default function AboutTruphone(props) {
  return (
    <div className="container mx-auto py-20 px-6 text-center">
      {props.topTitle && (
        <p className="text-xs md:text-base font-semibold tracking-widest mb-4">
          {uppercaseString(props.topTitle)}
        </p>
      )}
      {props.title && <h2 className="text-base">{props.title}</h2>}
      {props.content && <p className="text-xs mt-8 max-w-5xl mx-auto">{props.content}</p>}
    </div>
  )
}
