import React from 'react'
import { Arrow } from './Icons'
import { HeadingH3 } from './Typography'
import '../style.css'
import { uppercaseString } from '@truphone/utils'

export default function Block2Sides(props) {
  return (
    <div
      id={props.id}
      className={`py-5 lg:py-20 ${props.imageOnTheLeft ? 'bg-white' : 'bg-grey-100'}`}
    >
      <div className="container mx-auto px-6">
        <div className={`-mx-5 flex flex-wrap ${props.imageOnTheLeft ? 'flex-row-reverse' : ''}`}>
          <div className="w-full lg:w-1/2 p-5">
            <p className="overline-text mb-4">{uppercaseString(props.topTitle)}</p>
            <HeadingH3 className="mb-4">{props.title}</HeadingH3>
            <div className="text-base mb-9">{props.content}</div>
            {props.benefits && (
              <div className="flex flex-nowrap lg:flex-wrap -m-3 overflow-x-auto">
                {props.benefits.map((item, i) => {
                  return (
                    <div key={`benefit-block-${i}`} className="min-w--250 w-full md:w-1/2 p-3">
                      <a
                        href={item.href}
                        className={`text-base h-full block pl-5 pr-6 pt-8 pb-5 ${
                          props.imageOnTheLeft
                            ? 'bg-green-200'
                            : props.darkBlue
                            ? 'bg-blue-300'
                            : 'bg-blue-100'
                        } text-white rounded-sm hover:bg-gradient-home`}
                      >
                        <h5 className="font-semibold mb-2">{item.title}</h5>
                        <p>{item.content}</p>
                        <div className="flex mt-2">
                          <Arrow className="ml-auto -rotate-90 w-6 h-6" />
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="w-full lg:w-1/2 flex flex-col justify-center p-5">
            {props.image && (
              <div className="w-full flex px-4">
                <img className="m-auto" src={props.image.url} alt={props.image.alt} />
              </div>
            )}
            {props.links && (
              <div className="flex flex-wrap -m-4 mt-5  shrink-0">
                {props.links.map((feature, i) => {
                  return (
                    <div key={`benefit-links-${i}`} className="w-full md:w-1/2 px-4 py-2 md:p-4">
                      <a
                        href={feature.href}
                        title={feature.title}
                        target={feature.openTab ? '_blank' : ''}
                        className={`px-4 py-6 ${
                          props.imageOnTheLeft ? 'bg-grey-100' : 'bg-white'
                        } flex rounder-sm hover:shadow-md`}
                      >
                        <h5 className="text-base font-bold">{feature.text}</h5>
                        <Arrow className="ml-auto -rotate-90 w-4 h-4text-blue-100" />
                      </a>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
