import React from 'react'
export default function Products(props) {
  return (
    <div className="bg-grey-100 pt-4 pb-20">
      {props.title && <h2 className="text-3xl font-light text-center py-6">{props.title}</h2>}

      <div className="container mx-auto">
        {props.products && (
          <div className="flex flex-wrap justify-center -mx-4">
            {props.products.map((product, i) => {
              return (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-4 pt-16" key={`product-${i}`}>
                  <a
                    href={product.link}
                    className="mx-4 block relative h-full bg-white shadow hover:shadow-lg rounded px-4 pb-6 pt-12 text-center"
                  >
                    {product.image && (
                      <div className="absolute -top-12 left-1/2 -translate-x-1/2 bg-white w-20 h-20 rounded-lg shadow flex items-center justify-center">
                        <img
                          className="m-auto w-10 h-10"
                          src={product.image.url}
                          alt={product.image.alt}
                        />
                      </div>
                    )}
                    {product.title && <h3 className="text-2xl">{product.title}</h3>}
                    {product.content && (
                      <div
                        className="mt-4 text-base"
                        dangerouslySetInnerHTML={{ __html: product.content }}
                      />
                    )}
                  </a>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
