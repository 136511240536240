import React from 'react'
import Checkbox from './Checkbox'
import RadioButton from './RadioButton'
import Field from './Field'
import CountryList from './CountryList'
import Slider from './Slider'
import MultiSelect from './MultiSelect'
import { useFormContext } from 'react-hook-form'

function FieldWrapper(props) {
  const { watch } = useFormContext()
  if (props.dependencyConditions && props.dependencyConditions.length > 0) {
    let conditionsMatch = false
    let stopProcessing = false

    props.dependencyConditions.forEach((condition) => {
      if (stopProcessing) return

      const fieldValue = watch(condition.field)
      if (condition.condition == 'equals' && fieldValue == condition.value) {
        conditionsMatch = true
      } else if (condition.condition == 'notequals' && fieldValue != condition.value) {
        conditionsMatch = true
      } else if (
        condition.condition == 'contains' &&
        fieldValue &&
        fieldValue.includes(condition.value)
      ) {
        conditionsMatch = true
      } else if (
        condition.condition == 'notcontains' &&
        fieldValue &&
        !fieldValue.includes(condition.value)
      ) {
        conditionsMatch = true
      } else if (
        condition.condition == 'matches' &&
        fieldValue &&
        new RegExp(condition.value).test(fieldValue)
      ) {
        conditionsMatch = true
      } else {
        conditionsMatch = false
      }

      if (conditionsMatch && props.dependencyConditionGrouping == 'any') {
        stopProcessing = true
      }
    })

    if (!conditionsMatch && props.dependencyVisibility == 'shown') {
      return <></>
    } else if (conditionsMatch && props.dependencyVisibility == 'hidden') {
      return <></>
    }
  }

  return (
    <div className={`relative w-full p-2 ${props.short ? 'md:w-1/2' : ''}`}>
      {(props.type === 'Text' ||
        props.type === 'Email' ||
        props.type === 'TextArea' ||
        props.type === 'Number' ||
        props.type === 'Date' ||
        (props.type === 'Select' && !props.selectSlider)) && <Field {...props} />}

      {props.type === 'CountryList' && <CountryList {...props} />}
      {props.type === 'YesNo' && <RadioButton {...props} />}

      {props.type === 'checkbox' && <Checkbox {...props} />}
      {props.type === 'MultiSelect' && <MultiSelect {...props} />}
      {props.type === 'Select' && props.selectSlider && <Slider {...props} />}
    </div>
  )
}

export default FieldWrapper
