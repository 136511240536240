import React, { useEffect, useState } from 'react'
import { Twitter, Facebook, LinkedIn, Link } from './Icons'

export default function Share(props) {
  const [shareUrl, setShareUrl] = useState('')
  const [copied, setCopied] = useState('')

  useEffect(() => {
    setShareUrl(document.location.href)
  }, [])

  const copyTextToClipboard = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(document.location.href)
    setCopied('copied')
    setTimeout(() => {
      setCopied('')
    }, 1000)
  }

  return (
    <div className="hidden lg:block relative mr-10">
      <div className="sticky top-3 max-w-73 px-4 py-2 pb-1 shadow-lg rounded-sm bg-white">
        <p className="py-2 text-sm border-b border-grey-200 mb-2">{props.shareTitle}</p>
        <a
          className="my-5 mx-1 block origin-center transition-all hover:scale-125"
          target="_blank"
          href={`https://www.linkedin.com/shareArticle?url=${shareUrl}`}
        >
          <LinkedIn className="w-6 mx-auto" />
        </a>
        <a
          className="my-5 mx-1 block origin-center transition-all hover:scale-125"
          target="_blank"
          href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
        >
          <Twitter className="w-6 mx-auto" />
        </a>
        <a
          className="my-5 mx-1 block origin-center transition-all hover:scale-125"
          target="_blank"
          href={`https://www.facebook.com/sharer.php?u=${shareUrl}`}
        >
          <Facebook className="w-6 mx-auto" />
        </a>
        <a
          className="my-5 mx-1 block origin-center transition-all hover:scale-125"
          onClick={copyTextToClipboard}
          href="#"
        >
          <Link className="w-6 mx-auto" />
        </a>

        {copied && (
          <div className="text-xs absolute h-8 -ml-2 -mb-8 p-2 bg-white rounded-sm shadow">
            Copied
          </div>
        )}
      </div>
    </div>
  )
}
