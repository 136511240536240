import React, { useState } from 'react'
import BannerCore from './BannerCore'
export default function BannerCoreTabs(props) {
  const [visible, setVisible] = useState(0)
  return (
    <div className="relative">
      {props.items &&
        props.items.length > 0 &&
        props.items.map((item, i) => (
          <div key={`banner-tab-${i}`} className={`${visible === i ? 'block' : 'hidden'}`}>
            <BannerCore linkArrow {...item} />
          </div>
        ))}
      <div className="relative -mt-6 z-2 text-center">
        {props.items &&
          props.items.length > 0 &&
          props.items.map((item, i) => (
            <div
              onClick={() => setVisible(i)}
              key={`banner-tab-icon-${i}`}
              className={`cursor-pointer ${
                visible === i ? 'bg-blue-900 text-white' : 'bg-white text-blue-900'
              } inline-flex mx-2 w-12 h-12 rounded-full items-center justify-center shadow`}
            >
              {item.icon && item.icon.url && (
                <div
                  className={`w-6 h-6 ${visible === i ? ' bg-white' : ' bg-blue-900'}`}
                  style={{
                    mask: `url(${item.icon.url}) no-repeat center /contain`,
                    WebkitMask: `url(${item.icon.url}) no-repeat center /contain`
                  }}
                ></div>
              )}
              {/* <img className="w-6 h-6" src={w-6 h-6} alt={item.icon.alt} /> */}
            </div>
          ))}
      </div>
    </div>
  )
}
